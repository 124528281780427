import React from "react";

import { Link } from "react-router-dom";
import Layout from "../../components/Layout";

import "./../../styles/components/places-list.scss";

function HomePage() {
    const countries = [
        { code: "bra", name: "Brazil", link: "/place/country/Brazil" },
        {
            code: "usa",
            name: "United States",
            link: "/place/country/UnitedStates",
        },
        { code: "fra", name: "France", link: "/place/country/France" },
        {
            code: "zaf",
            name: "South Africa",
            link: "/place/country/SouthAfrica",
        },
        { code: "ind", name: "India", link: "/place/country/India" },
        { code: "aus", name: "Australia", link: "/place/country/Australia" },
        { code: "egy", name: "Egypt", link: "/place/country/Egypt" },
        { code: "jpn", name: "Japan", link: "/place/country/Japan" },
        { code: "deu", name: "Germany", link: "/place/country/Germany" },
        { code: "arg", name: "Argentina", link: "/place/country/Argentina" },
    ];

    const cities = [
        {
            code: "rio",
            name: "Rio de Janeiro",
            country: "Brazil",
            link: "/place/city/RioDeJaneiro",
        },
        {
            code: "nyc",
            name: "New York",
            country: "United States",
            link: "/place/city/NewYork",
        },
        {
            code: "par",
            name: "Paris",
            country: "France",
            link: "/place/city/Paris",
        },
        {
            code: "cpt",
            name: "Cape Town",
            country: "South Africa",
            link: "/place/city/CapeTown",
        },
        {
            code: "del",
            name: "Delhi",
            country: "India",
            link: "/place/city/Delhi",
        },
        {
            code: "syd",
            name: "Sydney",
            country: "Australia",
            link: "/place/city/Sydney",
        },
        {
            code: "cai",
            name: "Cairo",
            country: "Egypt",
            link: "/place/city/Cairo",
        },
        {
            code: "tky",
            name: "Tokyo",
            country: "Japan",
            link: "/place/city/Tokyo",
        },
        {
            code: "ber",
            name: "Berlin",
            country: "Germany",
            link: "/place/city/Berlin",
        },
        {
            code: "bue",
            name: "Buenos Aires",
            country: "Argentina",
            link: "/place/city/BuenosAires",
        },
    ];

    return (
        <>
            <Layout>
                <div className="pl">
                    <h2 className="pl__title">Популярные страны</h2>
                    <ul className="pl-list">
                        {countries.map((country, index) => (
                            <li className="pl-list__item" key={index}>
                                <Link
                                    to={country.link}
                                    className="pl-list__link"
                                >
                                    <span className="pl-list__img">
                                        <img
                                            src={`img/temp/${country.code}.jpg`}
                                            alt={country.name}
                                        />
                                    </span>
                                    <span className="pl-list__title">
                                        {country.name}
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <a
                        href="/"
                        className="button button--t3 button--s3 button--centered"
                    >
                        Show more
                    </a>
                </div>
                <div className="pl">
                    <h2 className="pl__title">Популярные города</h2>
                    <ul className="pl-list">
                        {cities.map((city, index) => (
                            <li className="pl-list__item" key={index}>
                                <Link to={city.link} className="pl-list__link">
                                    <span className="pl-list__img">
                                        <img
                                            src={`img/temp/${city.code}.jpg`}
                                            alt={city.name}
                                        />
                                    </span>
                                    <span className="pl-list__title">
                                        {city.name}
                                    </span>
                                    <span className="pl-list__subtitle">
                                        {city.country}
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <a
                        href="/"
                        className="button button--t3 button--s3 button--centered"
                    >
                        Show more
                    </a>
                </div>
            </Layout>
        </>
    );
}

export default HomePage;
