import { useCallback } from "react";
import html2canvas from "html2canvas";

const useScreenshot = () => {
  const takeScreenshot = useCallback((ref) => {
    return new Promise(async (resolve, reject) => {
      const mapBlock = ref.current;
      if (!mapBlock) {
        reject("Ref is not assigned to an element.");
        return;
      }

      const width = mapBlock.clientWidth;
      const height = mapBlock.clientHeight;

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      await html2canvas(mapBlock, {
        canvas: canvas,
        scale: 1,
        logging: false,
      })
        .then((canvas) => {
          canvas.toBlob((blob) => {
            const screenshotUrl = URL.createObjectURL(blob);
            resolve(screenshotUrl);
          }, "image/png");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, []);

  return { takeScreenshot };
};

export default useScreenshot;
