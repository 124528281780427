import React, { useRef, useEffect, useState, useCallback } from "react";
import MapS, { Marker } from "react-map-gl";
import { useSelector } from "react-redux";
import { selectAuthToken } from "../reducers/authReducer";
import useAuthRedirect from "../hooks/useAuthRedirect";

import Layout from "../components/Layout";
import Loader from "../components/Loader";
import { MAPBOX_TOKEN } from "./../constants/variables";
import { fetchData, updateSettings, updateMapSizes } from "../functions";

import "./../styles/pages/map-page.scss";
import "./../styles/components/admin.scss";
import "./../styles/components/input.scss";
import "./../styles/components/checkbox.scss";
import "./../styles/components/vendor/mapbox-gl.scss";

export default function MapAdmin() {
  const authToken = useSelector(selectAuthToken);

  useAuthRedirect(authToken);

  const defaultZoom = 12;
  const minZoomDefault = 0.5;
  const minZoomAfterLoad = 3;

  const mapRef = useRef(null);
  const maskCanvasRef = useRef(null);
  const prevCenterRef = useRef(null);

  const [data, setData] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [innerRadius, setInnerRadius] = useState(0);
  const [outerRadius, setOuterRadius] = useState(0);
  const [drawRadius, setDrawRadius] = useState(0);
  const [userMarker, setUserMarker] = useState(null);
  const [verticalSize, setVerticalSize] = useState(0);
  const [horizontalSize, setHorizontalSize] = useState(0);
  const [viewState, setViewState] = useState({
    latitude: 0,
    longitude: Math.random() * 360 - 180,
    zoom: minZoomDefault,
  });
  const [locationReady, setLocationReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [prevZoom, setPrevZoom] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isFlyAnimationFinished, setIsFlyAnimationFinished] = useState(false);
  const [hasDefaultZoomRequestBeenSent, setHasDefaultZoomRequestBeenSent] =
    useState(false);
  const [
    isUpdateUserPositionOnDrugChecked,
    setIsUpdateUserPositionOnDrugChecked,
  ] = useState(false);

  console.log(loading);

  const fetchDataWithLoading = useCallback(
    async (latitude, longitude, mapScale = 13) => {
      const showLoading = prevZoom === null;
      if (showLoading) setLoading(true);

      if (!isFlyAnimationFinished && !hasDefaultZoomRequestBeenSent) {
        mapScale = defaultZoom;
        setHasDefaultZoomRequestBeenSent(true);
      }

      if (!isFlyAnimationFinished && hasDefaultZoomRequestBeenSent) return;

      if (authToken) {
        fetchData(latitude, longitude, mapScale)
          .then((zoomData) => {
            setData(zoomData);
            if (mapScale in zoomData) {
              setInnerRadius(zoomData[mapScale].innerRadius);
              setOuterRadius(zoomData[mapScale].outerRadius);
              setDrawRadius(zoomData[mapScale].drawRadius);
            }
            if (showLoading) {
              setLoading(false);
              if (initialLoading) {
                setInitialLoading(false);
              }
            }
          })
          .catch((error) => {
            console.log(
              "Error message:",
              error.message,
              "Error object:",
              error
            );
            if (showLoading) {
              setLoading(false);
              if (initialLoading) {
                setInitialLoading(false);
              }
            }
          });
      }
    },
    [
      prevZoom,
      isFlyAnimationFinished,
      hasDefaultZoomRequestBeenSent,
      authToken,
      initialLoading,
      defaultZoom,
    ]
  );

  // Определение локации пользователя
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLat = position.coords.latitude;
          const userLon = position.coords.longitude;
          setUserLocation({ lat: userLat, lon: userLon });
          setLocationReady(true);

          setViewState((viewState) => ({
            ...viewState,
          }));
          fetchDataWithLoading(userLat, userLon, defaultZoom);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, [fetchDataWithLoading, isMapLoaded]);

  useEffect(() => {
    if (!locationReady) return;
    const zoom = Math.round(viewState.zoom);
    if (zoom !== prevZoom) {
      fetchDataWithLoading(viewState.latitude, viewState.longitude, zoom);
      setPrevZoom(zoom);
    }
  }, [
    locationReady,
    viewState.zoom,
    viewState.latitude,
    viewState.longitude,
    prevZoom,
    fetchDataWithLoading,
  ]);

  useEffect(() => {
    const zoomLvl = viewState && viewState.zoom && viewState.zoom.toFixed(0);

    if (data && data[zoomLvl]) {
      setInnerRadius(data[zoomLvl].innerRadius);
      setOuterRadius(data[zoomLvl].outerRadius);
      setDrawRadius(data[zoomLvl].drawRadius);
    }
  }, [viewState.zoom, data, viewState]);

  useEffect(() => {
    if (isFlyAnimationFinished) {
      setViewState({ ...viewState, minZoom: minZoomAfterLoad });
    }
  }, [isFlyAnimationFinished, viewState]);
  useEffect(() => {
    if (!mapRef.current || !data) {
      return;
    }

    const mask = maskCanvasRef.current;
    const ctx = mask.getContext("2d");

    setTimeout(() => {
      mask.classList.add("map__canvas--show");
    }, 8000);

    const updateMask = () => {
      const size = mapRef.current.getMap().getCanvas().getBoundingClientRect();
      mask.width = size.width;
      mask.height = size.height;
      ctx.clearRect(0, 0, mask.width, mask.height);
      ctx.fillStyle = "rgba(0, 0, 0, 1)";
      ctx.fillRect(0, 0, mask.width, mask.height);
      ctx.globalCompositeOperation = "destination-out";

      const zoomLvl = viewState && viewState.zoom && viewState.zoom.toFixed(0);

      if (data[zoomLvl]) {
        data[zoomLvl].coordinatesArray.forEach((point) => {
          const p = mapRef.current.getMap().project(point);
          ctx.beginPath();
          ctx.arc(p.x, p.y, drawRadius, 0, 2 * Math.PI);
          ctx.fill();
        });
      }
      ctx.globalCompositeOperation = "source-over";
    };

    updateMask();
  }, [data, drawRadius, viewState]);

  // обновляем маркер при изменении координат пользователя
  useEffect(() => {
    if (userLocation && isFlyAnimationFinished) {
      setUserMarker(
        <Marker latitude={userLocation.lat} longitude={userLocation.lon}>
          <img
            className="marker"
            src={`${process.env.PUBLIC_URL}/img/marker.png`}
            alt="user location"
          />
        </Marker>
      );
    }
  }, [userLocation, isFlyAnimationFinished]);

  const handleInnerRadiusChange = async (event) => {
    setInnerRadius(event.target.value);
  };

  const handleInnerRadiusBlur = async (event) => {
    const zoom = viewState.zoom.toFixed(0);
    const newRadius = event.target.value;
    const code = `InnerRadius${zoom}`;
    const response = await updateSettings(code, newRadius);
    if (response && data[zoom]) {
      data[zoom] = {
        ...data[zoom],
        innerRadius: newRadius,
      };
    }
  };

  const handleOuterRadiusChange = async (event) => {
    setOuterRadius(event.target.value);
  };

  const handleOuterRadiusBlur = async (event) => {
    const zoom = viewState.zoom.toFixed(0);
    const newRadius = event.target.value;
    const code = `OuterRadius${zoom}`;
    const response = await updateSettings(code, newRadius);

    if (response && data[zoom]) {
      data[zoom] = {
        ...data[zoom],
        outerRadius: newRadius,
      };
    }
  };

  const handleDrawRadiusChange = async (event) => {
    setDrawRadius(event.target.value);
  };

  const handleDrawRadiusBlur = async (event) => {
    const zoom = viewState.zoom.toFixed(0);
    const newRadius = event.target.value;
    const code = `DrawRadius${zoom}`;
    const response = await updateSettings(code, newRadius);

    if (response && data[zoom]) {
      data[zoom] = {
        ...data[zoom],
        drawRadius: newRadius,
      };
    }
  };

  useEffect(() => {
    const sizes = updateMapSizes(mapRef);
    if (sizes) {
      setVerticalSize(sizes[0].verticalSize);
      setHorizontalSize(sizes[1].horizontalSize);
    }
  }, [isMapLoaded, viewState]);

  useEffect(() => {
    setTimeout(() => {
      if (mapRef.current && isMapLoaded) {
        mapRef.current.getMap().flyTo({
          center: [userLocation.lon, userLocation.lat],
          zoom: defaultZoom,
          duration: 7000,
        });
      }
    }, 1000);
  }, [isMapLoaded, userLocation.lat, userLocation.lon]);

  // обновлять или нет координаты при перетаскивании
  const handleUpdateUserPositionOnDrugChecked = (event) => {
    setIsUpdateUserPositionOnDrugChecked(event.target.checked);
  };

  // после окончания загрузки карты
  const onMapLoadHandler = () => {
    console.log("окончание загрузки");

    setIsMapLoaded(true);
  };

  // при перемещении карты
  const onMapMoveHandler = (e) => {
    console.log("перемещение");
    setViewState({
      ...e.viewState,
      ...(isFlyAnimationFinished ? { minZoom: minZoomAfterLoad } : null),
    });
  };

  // после окончания перемещения карты
  const onMapMoveEndHandler = (e) => {
    console.log("Конец перемещения");
    setViewState({
      ...e.viewState,
      ...(isFlyAnimationFinished ? { minZoom: minZoomAfterLoad } : null),
    });

    if (!isUpdateUserPositionOnDrugChecked) {
      const newCenter = {
        lat: e.viewState.latitude,
        lon: e.viewState.longitude,
      };
      const prevCenter = prevCenterRef.current;

      if (prevCenter) {
        const bounds = mapRef.current.getMap().getBounds();
        const width = bounds._ne.lng - bounds._sw.lng;
        const height = bounds._ne.lat - bounds._sw.lat;

        const latDiff = Math.abs(prevCenter.lat - newCenter.lat);
        const lngDiff = Math.abs(prevCenter.lon - newCenter.lon);

        const latThreshold = height * 0.25;
        const lngThreshold = width * 0.25;

        if (
          (latDiff > latThreshold && latDiff > lngDiff) ||
          (lngDiff > lngThreshold && lngDiff > latDiff)
        ) {
          const zoom = Math.round(viewState.zoom);
          fetchDataWithLoading(viewState.latitude, viewState.longitude, zoom);
        }
      }
      prevCenterRef.current = newCenter;
    }
  };

  // после окончания смены зума
  const onMapZoomEndHandler = (e) => {
    console.log(`Произошла смена зума на ${e.viewState.zoom.toFixed(0)}`);
    setIsFlyAnimationFinished(true);
    setViewState({
      ...e.viewState,
      ...(isFlyAnimationFinished ? { minZoom: minZoomAfterLoad } : null),
    });
  };

  const smallLoader = <Loader size={"small"} type={"inline"} color={"light"} />;

  return (
    <>
      <Layout className={"map map--admin"}>
        {!isMapLoaded ? <Loader size={"big"} type={"content"} /> : null}

        <ul className="admin-stat">
          <li>
            <span>Dots</span>
            <div>
              <b>
                {data
                  ? data[viewState.zoom.toFixed(0)]?.coordinatesArray.length
                  : smallLoader}
              </b>
            </div>
          </li>
          <li>
            <span>Lon</span>
            <div className={"admin-stat--coord"}>
              <b>
                {viewState && viewState.longitude
                  ? viewState.longitude.toFixed(6)
                  : smallLoader}
              </b>
            </div>
          </li>
          <li>
            <span>Lat</span>
            <div className={"admin-stat--coord"}>
              <b>
                {viewState && viewState.latitude
                  ? viewState.latitude.toFixed(6)
                  : smallLoader}
              </b>
            </div>
          </li>
          <li>
            <span>Zoom</span>
            <div>
              <b>
                {viewState && viewState.zoom
                  ? viewState.zoom.toFixed(0)
                  : smallLoader}
              </b>
            </div>
          </li>
          <li>
            {initialLoading ? (
              smallLoader
            ) : (
              <>
                <span>Inner radius</span>
                <div className="input admin-stat--radius">
                  <input
                    type="text"
                    id="innerRadius"
                    value={innerRadius}
                    onChange={handleInnerRadiusChange}
                    onBlur={handleInnerRadiusBlur}
                  />
                </div>
              </>
            )}
          </li>
          <li>
            {initialLoading ? (
              smallLoader
            ) : (
              <>
                <span>Outer radius</span>
                <div className="input admin-stat--radius">
                  <input
                    type="text"
                    id="outerRadius"
                    value={outerRadius}
                    onChange={handleOuterRadiusChange}
                    onBlur={handleOuterRadiusBlur}
                  />
                </div>
              </>
            )}
          </li>
          <li>
            {initialLoading ? (
              smallLoader
            ) : (
              <>
                <span>Draw radius</span>
                <div className="input admin-stat--radius">
                  <input
                    type="text"
                    id="drawRadius"
                    value={drawRadius}
                    onChange={handleDrawRadiusChange}
                    onBlur={handleDrawRadiusBlur}
                  />
                </div>
              </>
            )}
          </li>
          <li>
            <div className="checkbox">
              <input
                type="checkbox"
                id="checkbox"
                checked={isUpdateUserPositionOnDrugChecked}
                onChange={handleUpdateUserPositionOnDrugChecked}
              />
              <label htmlFor="checkbox">Don't update data</label>
            </div>
          </li>
          <li>
            {initialLoading ? (
              smallLoader
            ) : (
              <>
                <span>Map width</span>
                <div>
                  <b>{horizontalSize}</b> m
                </div>
              </>
            )}
          </li>
          <li>
            {initialLoading ? (
              smallLoader
            ) : (
              <>
                <span>Map height</span>
                <div>
                  <b>{verticalSize}</b> m
                </div>
              </>
            )}
          </li>
        </ul>

        <>
          <div style={{ position: "relative" }}>
            <div className={"map__container map--1"}>
              <MapS
                {...viewState}
                maxZoom={16}
                projection={"globe"}
                onLoad={() => onMapLoadHandler()}
                onMove={(evt) => onMapMoveHandler(evt)}
                onMoveEnd={(evt) => onMapMoveEndHandler(evt)}
                onZoomEnd={(evt) => onMapZoomEndHandler(evt)}
                mapStyle="mapbox://styles/mapbox/streets-v12"
                mapboxAccessToken={MAPBOX_TOKEN}
                ref={mapRef}
              >
                {userMarker}
              </MapS>
            </div>
            <canvas ref={maskCanvasRef} className={"map__canvas"} />
          </div>
        </>
      </Layout>
    </>
  );
}
