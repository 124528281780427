import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import axios from "axios";
import { API_URL } from "../../constants/variables";
import { registrationValidationSchema } from "../../validation/registrationValidation";
import { selectAuthToken } from "../../reducers/authReducer";
import { setAuthToken, setUser } from "../../actions/authActions";

export const useRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authTokenCheck = useSelector(selectAuthToken);
  const [registrationError, setRegistrationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(registrationValidationSchema),
  });

  useEffect(() => {
    if (authTokenCheck) {
      navigate("/");
    }
  }, [authTokenCheck, navigate]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    setRegistrationError("");
    try {
      const registrationResponse = await axios.post(`${API_URL}/user`, {
        login: data.login,
        password: data.password,
        passwordRepeat: data.passwordRepeat,
        email: data.email,
        phone: data.phone,
        firstName: data.firstName,
        lastName: data.lastName,
      });

      if (
        registrationResponse.status === 200 ||
        registrationResponse.status === 201
      ) {
        console.log("Пользователь зарегистрирован:", registrationResponse.data);

        try {
          const loginResponse = await axios.post(`${API_URL}/auth/login`, {
            login: data.login,
            password: data.password,
          });

          const { token, refreshToken, expiration } = loginResponse.data;
          localStorage.setItem("authToken", token);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("tokenExpiration", expiration);
          dispatch(setAuthToken(token));

          const headers = {
            Authorization: `Bearer ${token}`,
          };
          const userResponse = await axios.get(`${API_URL}/user`, { headers });
          dispatch(setUser(userResponse.data));

          navigate("/");
        } catch (loginError) {
          console.error("Ошибка при авторизации:", loginError);
          setRegistrationError(
            "Регистрация прошла успешно, но произошла ошибка при автоматической авторизации. Пожалуйста, войдите вручную."
          );
          navigate("/login");
        }
      } else {
        throw new Error("Unexpected response from server during registration");
      }
    } catch (error) {
      console.error("Ошибка при регистрации:", error);
      setRegistrationError("Ошибка при регистрации. Попробуйте еще раз.");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    registrationError,
    isLoading,
  };
};
