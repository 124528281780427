import { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import useCanvasUpdater from "../../hooks/useCanvasUpdater";
import { fetchData, isSignificantMove } from "../../functions";
import { Marker } from "react-map-gl";

function useMapComponent(props, ref) {
  const {
    initialViewState,
    onClick,
    onMouseMove,
    onViewStateChange,
    maxZoom = 16,
    mapStyle = "mapbox://styles/mapbox/streets-v12",
    projection = "mercator",
    preserveDrawingBuffer = true,
    isWithCanvas = false,
    customDrawRadius,
    customData,
    className = "",
    useUserLocation = false,
    shouldSaveMapStateToLocalStorage = false,
    scrollZoom = true,
    renderWorldCopies = true,
    canvasUpdater,
    isGetDots = false,
    isShowFlights = true,
    onMapLoadedAndReadyChange,
    onCanvasRendered,
    children,
  } = props;

  const drawRadii = useSelector((state) => state.map.drawRadii);

  const canvasRef = useRef(null);
  const prevCenterRef = useRef(null);
  const defaultZoom = 12;

  const [viewState, setViewState] = useState(initialViewState || null);

  const [data, setData] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [userMarker, setUserMarker] = useState(null);
  const [locationReady, setLocationReady] = useState(false);
  const [prevZoom, setPrevZoom] = useState(null);
  const [zoomDebounceTimer, setZoomDebounceTimer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [drawRadius, setDrawRadius] = useState(0);
  const [lastFetchRequestTime, setLastFetchRequestTime] = useState(null);
  const [isFetchDebounced, setIsFetchDebounced] = useState(false);
  const [moveEndDebounceTimer, setMoveEndDebounceTimer] = useState(null);

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isMapLoadedAndReady, setIsMapLoadedAndReady] = useState(true);
  const [isCanvasRendered, setIsCanvasRendered] = useState(false);

  /* eslint-disable-next-line no-unused-vars */
  const _unused = [
    locationReady,
    loading,
    isFetchDebounced,
    setZoomDebounceTimer,
  ];

  const updateCanvas = useCanvasUpdater((rendered) => {
    setIsCanvasRendered(rendered);
    onCanvasRendered?.(rendered);
  });

  // Обновляем данные только при изменении customData
  useEffect(() => {
    if (customData) {
      setData(customData);
    }
  }, [customData]);

  // Проверяем загрузку карты, и что данные для отображения получены
  useEffect(() => {
    if (isMapLoaded && (isWithCanvas ? data : true)) {
      setIsMapLoadedAndReady(false);
      onMapLoadedAndReadyChange?.(false);
    }
  }, [isMapLoaded, data, isWithCanvas, onMapLoadedAndReadyChange]);

  useEffect(() => {
    if (isWithCanvas && ref && ref.current && canvasRef && canvasRef.current) {
      const updateTimer = canvasUpdater ? 700 : 0;
      setTimeout(() => {
        updateCanvas(
          ref,
          canvasRef,
          customData ? customData : data,
          customDrawRadius ? customDrawRadius : drawRadius,
          isShowFlights
        );
        setIsCanvasRendered(true);
        onCanvasRendered?.(true);
      }, updateTimer);
    }
  }, [
    data,
    customData,
    drawRadius,
    customDrawRadius,
    isWithCanvas,
    ref,
    updateCanvas,
    viewState,
    canvasUpdater,
    isShowFlights,
    onCanvasRendered,
  ]);

  const fetchDataWithLoading = useCallback(
    async (latitude, longitude, mapScale = 13) => {
      const roundedMapScale = Math.round(mapScale);

      const showLoading = prevZoom === null;
      if (showLoading) setLoading(true);

      const currentRequestTime = new Date().getTime();
      if (
        lastFetchRequestTime &&
        currentRequestTime - lastFetchRequestTime < 3500
      ) {
        setIsFetchDebounced(true);
        return;
      }

      setLastFetchRequestTime(currentRequestTime);
      setIsFetchDebounced(false);

      fetchData(latitude, longitude, mapScale)
        .then((zoomData) => {
          if (
            zoomData[roundedMapScale] &&
            Array.isArray(zoomData[roundedMapScale].CoordinatesArray)
          ) {
            setData(zoomData[roundedMapScale].CoordinatesArray);
            setDrawRadius(zoomData[roundedMapScale].DrawRadius);
          } else {
            console.error(
              "Invalid data format",
              zoomData,
              "for map scale:",
              roundedMapScale
            );
          }
          if (mapScale in zoomData) {
            setDrawRadius(zoomData[mapScale].DrawRadius);
          }
        })
        .catch((error) => {
          console.log("Error message:", error.message, "Error object:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [prevZoom, lastFetchRequestTime]
  );

  // Логика открытия карты на координатах пользователя или из LocalStorage, если initialViewState не передан
  useEffect(() => {
    if (!initialViewState) {
      const savedLat = localStorage.getItem("WHmapCenterLat");
      const savedLon = localStorage.getItem("WHmapCenterLng");
      const savedZoom = localStorage.getItem("WHmapZoom");

      if (savedLat && savedLon && savedZoom) {
        setViewState({
          latitude: parseFloat(savedLat),
          longitude: parseFloat(savedLon),
          zoom: parseFloat(savedZoom),
        });
        fetchDataWithLoading(
          parseFloat(savedLat),
          parseFloat(savedLon),
          parseFloat(savedZoom)
        );
      } else if (useUserLocation && navigator.geolocation && isGetDots) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLat = position.coords.latitude;
            const userLon = position.coords.longitude;

            setUserLocation({ lat: userLat, lon: userLon });
            setLocationReady(true);

            setViewState({
              latitude: userLat,
              longitude: userLon,
              zoom: defaultZoom,
            });

            fetchDataWithLoading(userLat, userLon, defaultZoom);
          },
          (error) => {
            console.error("Geolocation error:", error);
          }
        );
      }
    }
  }, [fetchDataWithLoading, useUserLocation, isGetDots, initialViewState]);

  useEffect(() => {
    return () => {
      if (zoomDebounceTimer) {
        clearTimeout(zoomDebounceTimer);
      }
    };
  }, [zoomDebounceTimer]);

  // Сохраняем данные в localStorage только при изменении viewState
  useEffect(() => {
    if (
      !initialViewState &&
      shouldSaveMapStateToLocalStorage &&
      isMapLoaded &&
      viewState
    ) {
      localStorage.setItem("WHmapCenterLat", viewState.latitude);
      localStorage.setItem("WHmapCenterLng", viewState.longitude);
      localStorage.setItem("WHmapZoom", Math.round(viewState.zoom));
    }
  }, [
    isMapLoaded,
    viewState,
    shouldSaveMapStateToLocalStorage,
    initialViewState,
  ]);

  useEffect(() => {
    if (userLocation) {
      setUserMarker(
        <Marker latitude={userLocation.lat} longitude={userLocation.lon}>
          <img
            className="marker"
            src={`${process.env.PUBLIC_URL}/img/marker.png`}
            alt="user location"
          />
        </Marker>
      );
    }
  }, [userLocation]);

  const onMapMoveHandler = useCallback(
    (e) => {
      // Перерисовка канваса при каждом изменении зума и координат
      updateCanvas(
        ref,
        canvasRef,
        customData ? customData : data,
        customDrawRadius ? customDrawRadius : drawRadius,
        isShowFlights
      );

      // Обновление состояния координат и зума
      const { latitude, longitude, zoom } = e.viewState;
      setViewState(e.viewState);

      // Сохраняем координаты и зум в localStorage
      if (!initialViewState && shouldSaveMapStateToLocalStorage) {
        localStorage.setItem("WHmapCenterLat", latitude);
        localStorage.setItem("WHmapCenterLng", longitude);
        localStorage.setItem("WHmapZoom", Math.round(zoom));
      }

      onViewStateChange?.({
        ...e.viewState,
      });
    },
    [
      ref,
      canvasRef,
      data,
      drawRadius,
      customData,
      customDrawRadius,
      isShowFlights,
      updateCanvas,
      onViewStateChange,
      shouldSaveMapStateToLocalStorage,
      initialViewState,
    ]
  );

  const onMapMoveEndHandler = useCallback(
    (e) => {
      console.info("Конец перемещения");

      console.info(`Произошла смена зума на ${e.viewState.zoom.toFixed(0)}`);
      const newZoom = Math.round(e.viewState.zoom);
      let adjustedZoom = newZoom % 2 !== 0 ? newZoom + 1 : newZoom;
      setDrawRadius(drawRadii[adjustedZoom]);

      const newCenter = {
        lat: e.viewState.latitude,
        lon: e.viewState.longitude,
        map: ref.current,
      };

      const prevCenter = prevCenterRef.current;

      if (moveEndDebounceTimer) {
        clearTimeout(moveEndDebounceTimer);
      }

      const newTimer = setTimeout(() => {
        const thresholdPercent = 33;

        if (
          (isSignificantMove(newCenter, prevCenter, thresholdPercent) ||
            adjustedZoom !== prevZoom) &&
          isGetDots
        ) {
          fetchDataWithLoading(
            viewState.latitude,
            viewState.longitude,
            adjustedZoom
          );
        }
      }, 1500);

      setMoveEndDebounceTimer(newTimer);
      prevCenterRef.current = newCenter;
      setPrevZoom(adjustedZoom);
    },
    [
      drawRadii,
      ref,
      moveEndDebounceTimer,
      prevZoom,
      isGetDots,
      fetchDataWithLoading,
      viewState?.latitude,
      viewState?.longitude,
    ]
  );

  useEffect(() => {
    return () => {
      if (moveEndDebounceTimer) {
        clearTimeout(moveEndDebounceTimer);
      }
    };
  }, [moveEndDebounceTimer]);

  return {
    viewState,
    isMapLoadedAndReady,
    isCanvasRendered,
    canvasRef,
    userLocation,
    userMarker,
    onMapMoveHandler,
    onMapMoveEndHandler,
    setIsMapLoaded,
    mapStyle,
    maxZoom,
    projection,
    preserveDrawingBuffer,
    scrollZoom,
    renderWorldCopies,
    className,
    isWithCanvas,
    onClick,
    onMouseMove,
    children,
  };
}

export default useMapComponent;
