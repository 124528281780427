export const colorsLegend = [
  "rgba(255, 215, 0, 0.7)",
  "rgba(255, 140, 0, 0.7)",
  "rgba(220, 20, 60, 0.7)",
  "rgba(100, 149, 237, 0.7)",
  "rgba(30, 144, 255, 0.7)",
  "rgba(148, 0, 211, 0.7)",
  "rgba(75, 0, 130, 0.7)",
  "rgba(144, 238, 144, 0.7)",
  "rgba(60, 179, 113, 0.7)",
  "rgba(1, 204, 0, 0.7)",
];
