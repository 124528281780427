import Joi from "joi";

const loginRegex = /^[a-zA-Z0-9_]+$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/;
const nameRegex = /^[a-zA-Z\s]+$/;
const phoneRegex = /^\+?[0-9]{6,15}$/;

export const registrationValidationSchema = Joi.object({
  login: Joi.string().min(5).max(250).pattern(loginRegex).required().messages({
    "string.empty": "Логин обязателен",
    "string.min": "Логин должен быть не менее 5 символов",
    "string.max": "Логин должен быть не более 250 символов",
    "string.pattern.base":
      "Логин должен содержать только буквы, цифры и знаки подчеркивания",
    "any.required": "Логин обязателен",
  }),

  password: Joi.string().pattern(passwordRegex).required().messages({
    "string.empty": "Пароль обязателен",
    "string.pattern.base":
      "Пароль должен содержать хотя бы одну заглавную букву, одну строчную букву и одну цифру. Длина должна быть от 8 до 15 символов",
    "any.required": "Пароль обязателен",
  }),

  passwordRepeat: Joi.string().valid(Joi.ref("password")).required().messages({
    "any.only": "Пароли не совпадают",
    "any.required": "Повторите пароль",
  }),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "Введите корректный email",
      "string.empty": "Email обязателен",
      "any.required": "Email обязателен",
    }),

  firstName: Joi.string().pattern(nameRegex).required().messages({
    "string.empty": "Имя обязательно",
    "string.pattern.base": "Имя должно содержать только буквы",
    "any.required": "Имя обязательно",
  }),

  lastName: Joi.string().pattern(nameRegex).required().messages({
    "string.empty": "Фамилия обязательна",
    "string.pattern.base": "Фамилия должна содержать только буквы",
    "any.required": "Фамилия обязательна",
  }),

  phone: Joi.string().pattern(phoneRegex).required().messages({
    "string.empty": "Номер телефона обязателен",
    "string.pattern.base": "Неверный формат номера телефона",
    "any.required": "Номер телефона обязателен",
  }),
});
