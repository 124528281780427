import React from "react";
import cn from "classnames";
import useTabs from "./useTabs";

import "./../../styles/components/tabs.scss";

const Tabs = ({ tabs, anchorMode = false }) => {
    const { activeTab, tabRefs, handleTabClick } = useTabs(tabs, anchorMode);

    const onTabClickHandler = (index) => {
        handleTabClick(index);
    };

    return (
        <div className="tabs">
            <ul className="tabs__list">
                {tabs.map((tab, index) => (
                    <li
                        key={tab.id}
                        onClick={() => onTabClickHandler(index)}
                        className={cn("tabs__item", {
                            "tabs__item--active": activeTab === index,
                        })}
                    >
                        {tab.title}
                    </li>
                ))}
            </ul>
            <div className="tabs__content">
                {tabs.map((tab, index) => (
                    <div
                        key={tab.id}
                        ref={tabRefs.current[index]}
                        className={cn("tabs__block", {
                            "tabs__block--visible":
                                anchorMode || activeTab === index,
                            "tabs__block--hidden":
                                !anchorMode && activeTab !== index,
                        })}
                    >
                        {anchorMode && <h2 id={tab.id}>{tab.title}</h2>}
                        {tab.content}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tabs;
