import React, { memo } from "react";

const SidebarRightBlock = memo(({ children, title }) => {
  return (
    <div className={`sidebar-right__block`}>
      {title ? <h3>{title}</h3> : null}
      {children}
    </div>
  );
});

export default SidebarRightBlock;
