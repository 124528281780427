import React, { useMemo } from "react";
import { ReactSVG } from "react-svg";
import cn from "classnames";

export const Icon = React.memo(({ name, className, noWrap = false }) => {
  const imgError = (err) => {
    if (err) console.log();
  };

  const url = useMemo(() => {
    return `/icons/${name?.replace(".svg", "")}.svg`;
  }, [name]);

  if (!name) return null;

  if (noWrap)
    return <ReactSVG afterInjection={imgError} wrapper="span" src={url} />;

  return (
    <i className={cn(className)}>
      <ReactSVG afterInjection={imgError} wrapper="span" src={url} />
    </i>
  );
});

Icon.displayName = "Icon";

export default Icon;
