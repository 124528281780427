import React, { useEffect, useCallback, memo } from "react";
import { NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { API_URL } from "./../constants/variables";
import { selectAuthToken } from "../reducers/authReducer";
import { logoutUser, setUser } from "../actions/authActions";
import Loader from "../components/Loader";
import { Icon } from "../shared/ui";

import "./../styles/components/sidebar.scss";

const Sidebar = memo(() => {
    const authToken = useSelector(selectAuthToken);
    const isSidebarClosed = useSelector(
        (state) => state.sidebar.isSidebarClosed
    );
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        if (authToken && !user) {
            axios
                .get(`${API_URL}/user`, {
                    headers: { Authorization: `Bearer ${authToken}` },
                })
                .then((response) => {
                    dispatch(setUser(response.data));
                })
                .catch((error) => {
                    console.error(
                        "Ошибка при получении информации о пользователе:",
                        error
                    );
                });
        }
    }, [authToken, user, dispatch]);

    const handleLogout = useCallback(() => {
        dispatch(logoutUser());
        localStorage.removeItem("authToken");
        localStorage.removeItem("WHmapCenterLat");
        localStorage.removeItem("WHmapCenterLng");
        localStorage.removeItem("WHmapZoom");
    }, [dispatch]);

    const sidebarClass = isSidebarClosed ? " sidebar--closed" : "";

    return (
        <aside className={`sidebar${sidebarClass}`}>
            <div className="sidebar__wrap">
                <div className="sidebar__logo">
                    <NavLink to="/">
                        <Icon name="logo" />
                    </NavLink>
                </div>
                <div className="sidebar__content">
                    <nav className="sidebar-menu">
                        <ul>
                            <li className="sidebar-menu__item">
                                <NavLink to="/" className="sidebar-menu__link">
                                    <Icon name="home" />
                                    <span className="sidebar-menu__label">
                                        Главная
                                    </span>
                                </NavLink>
                            </li>
                            <li className="sidebar-menu__item">
                                <NavLink
                                    to="/directions"
                                    className="sidebar-menu__link"
                                >
                                    <Icon name="directions" />
                                    <span className="sidebar-menu__label">
                                        Направления
                                    </span>
                                </NavLink>
                            </li>
                            {authToken ? (
                                <>
                                    <li className="sidebar-menu__item">
                                        <NavLink
                                            to="/map"
                                            className="sidebar-menu__link"
                                        >
                                            <Icon name="map" />
                                            <span className="sidebar-menu__label">
                                                Карта
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-menu__item">
                                        <NavLink
                                            to="/map-admin"
                                            className="sidebar-menu__link"
                                        >
                                            <Icon name="map" />
                                            <span className="sidebar-menu__label">
                                                Карта (админ)
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-menu__item">
                                        <NavLink
                                            to="/statistics"
                                            className="sidebar-menu__link"
                                        >
                                            <Icon name="statistics" />
                                            <span className="sidebar-menu__label">
                                                Статистика
                                            </span>
                                        </NavLink>
                                    </li>
                                </>
                            ) : null}
                        </ul>
                    </nav>

                    {!authToken ? null : (
                        <>
                            <div className="sidebar-user">
                                {user ? (
                                    <>
                                        <p className="sidebar-user__name">
                                            <Link to="/profile">
                                                {user.login}
                                            </Link>
                                        </p>
                                        <p className="sidebar-user__name">
                                            <Link to="/settings">
                                                Настройки
                                            </Link>
                                        </p>
                                    </>
                                ) : (
                                    <Loader
                                        size={"big"}
                                        type={"inline"}
                                        color={"light"}
                                    />
                                )}

                                <div className="sidebar-user__exit">
                                    <span onClick={handleLogout}>Выход</span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </aside>
    );
});

export default Sidebar;
