import React from "react";
import Select from "react-select";

const SelectComponent = (props) => {
    // стили для позиционирования выпадашки селекта относительно самого селекта
    const customStyles = {
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
        }),
    };

    const combinedProps = {
        ...props,
        styles: { ...props.styles, ...customStyles },
        menuPortalTarget: document.body,
    };

    return <Select {...props} {...combinedProps} />;
};

export default SelectComponent;
