import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import authReducer from "./reducers/authReducer";
import sidebarReducer from "./reducers/sidebarReducer";
import mapReducer from "./reducers/mapReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  map: mapReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
