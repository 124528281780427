import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import axios from "axios";
import { API_URL } from "../../constants/variables";
import { loginValidationSchema } from "../../validation/loginValidation";
import { selectAuthToken } from "../../reducers/authReducer";
import { setAuthToken, setUser } from "../../actions/authActions";

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authTokenCheck = useSelector(selectAuthToken);
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(loginValidationSchema),
  });

  useEffect(() => {
    if (authTokenCheck) {
      navigate("/");
    }
  }, [authTokenCheck, navigate]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    setLoginError("");
    try {
      const response = await axios.post(`${API_URL}/auth/login`, data);
      const { token, refreshToken, expiration } = response.data;
      localStorage.setItem("authToken", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("tokenExpiration", expiration);
      dispatch(setAuthToken(token));
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const userResponse = await axios.get(`${API_URL}/user`, { headers });
      dispatch(setUser(userResponse.data));
      navigate("/");
    } catch (error) {
      if (error.response && error.response.data) {
        setLoginError("Неверный логин или пароль");
      } else {
        setLoginError("Ошибка при проверке");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    loginError,
    isLoading,
  };
};
