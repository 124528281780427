import React, { useState, useRef, useEffect } from "react";

const useTabs = (
    tabs,
    anchorMode = false,
    headerSelector = ".header",
    additionalOffset = 20
) => {
    const [activeTab, setActiveTab] = useState(0);
    const tabRefs = useRef(tabs.map(() => React.createRef()));
    const [headerHeight, setHeaderHeight] = useState(0);

    useEffect(() => {
        const updateHeaderHeight = () => {
            const headerElement = document.querySelector(headerSelector);
            if (headerElement) {
                setHeaderHeight(headerElement.offsetHeight);
            }
        };

        updateHeaderHeight();
        window.addEventListener("resize", updateHeaderHeight);

        return () => {
            window.removeEventListener("resize", updateHeaderHeight);
        };
    }, [headerSelector]);

    useEffect(() => {
        if (anchorMode) {
            const scrollToActiveTab = () => {
                const targetElement = tabRefs.current[activeTab].current;
                if (targetElement) {
                    const targetPosition =
                        targetElement.getBoundingClientRect().top;
                    const offsetPosition =
                        targetPosition +
                        window.pageYOffset -
                        (headerHeight + additionalOffset);

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                    });
                }
            };

            scrollToActiveTab();
        }
    }, [anchorMode, activeTab, tabs, headerHeight, additionalOffset]);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return { activeTab, tabRefs, handleTabClick };
};

export default useTabs;
