import React from "react";
import Layout from "../../components/Layout";
import { useSettings } from "./useSettings";

function SettingsPage() {
    const { user } = useSettings();

    return (
        <>
            <Layout>
                <h2>Настройки пользователя</h2>
                <form>
                    <div className="form-row">
                        <div className="form-element">
                            <label className="form-label">Логин:</label>
                            <div className="input">
                                <input
                                    type="text"
                                    name="userName"
                                    value={user ? user.login : ""}
                                    autoComplete="off"
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-element">
                            <label className="form-label">Почта:</label>
                            <div className="input">
                                <input
                                    type="text"
                                    name="userEmail"
                                    value={user ? user.email : ""}
                                    autoComplete="off"
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row form-row--button">
                        <div className="form-element">
                            <span className="button button--t1 button--disabled">
                                Редактировать
                            </span>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    );
}

export default SettingsPage;
