// src/pages/Profile/useProfile.js

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../constants/variables";
import { selectAuthToken } from "../../reducers/authReducer";
import { colorsLegend } from "../../constants/colors-for-map-label";

export const useProfile = () => {
    const authToken = useSelector(selectAuthToken);
    const user = useSelector((state) => state.auth.user);
    const [stats, setStats] = useState({
        continents: 0,
        countries: 0,
        cities: 0,
        continentNames: [],
        countryNames: [],
        cityNames: [],
        countryCodes: [],
        continentCountriesCount: {},
        countryCitiesCount: {},
        continentPercentages: {},
        countryPercentages: {},
    });
    const [countryPoints, setCountryPoints] = useState({});
    const [isLoadingStatistics, setIsLoadingStatistics] = useState(true);
    const [viewState, setViewState] = useState({
        latitude: 0,
        longitude: 0,
        zoom: 0.01,
    });
    const [tooltipInfo, setTooltipInfo] = useState(null);
    const [popupInfo, setPopupInfo] = useState(null);

    const calculatePercentage = (points, squarePoints) => {
        return squarePoints > 0
            ? ((points / squarePoints) * 100).toFixed(5)
            : 0;
    };

    const countPlaces = useCallback((items) => {
        let continents = 0,
            countries = 0,
            cities = 0;
        let continentNames = new Set(),
            countryNames = new Set(),
            cityNames = new Set();
        let countryCodes = new Set();
        let countryPointsMap = {};
        let continentCountriesCount = {};
        let countryCitiesCount = {};
        let continentPercentages = {};
        let countryPercentages = {};

        const traverseItems = (items, continent = null, country = null) => {
            items.forEach((item) => {
                const name = item.countryPointsList.name;
                const type = item.type;
                const points = item.points;
                const squarePoints = item.countryPointsList.squarePoints;
                const code = item.countryPointsList.code;

                switch (type) {
                    case "Continent":
                        if (code !== "WO") {
                            continents++;
                            continentNames.add(name);
                            continent = name;
                            continentCountriesCount[name] = 0;
                            continentPercentages[name] = calculatePercentage(
                                points,
                                squarePoints
                            );
                        }
                        break;
                    case "Country":
                        countries++;
                        countryNames.add(name);
                        country = name;
                        countryCodes.add(code);
                        countryPointsMap[code] = { points, squarePoints };
                        countryPercentages[name] = calculatePercentage(
                            points,
                            squarePoints
                        );
                        continentCountriesCount[continent]++;
                        countryCitiesCount[name] = 0;
                        break;
                    case "City":
                        cities++;
                        cityNames.add(name);
                        if (country) {
                            countryCitiesCount[country]++;
                        }
                        break;
                    default:
                        break;
                }
                if (item.items && item.items.$values) {
                    traverseItems(item.items.$values, continent, country);
                }
            });
        };

        traverseItems(items);
        setStats({
            continents,
            countries,
            cities,
            continentNames: Array.from(continentNames),
            countryNames: Array.from(countryNames),
            cityNames: Array.from(cityNames),
            countryCodes: Array.from(countryCodes),
            continentCountriesCount,
            countryCitiesCount,
            continentPercentages,
            countryPercentages,
        });
        setCountryPoints(countryPointsMap);
    }, []);

    useEffect(() => {
        if (authToken) {
            const headers = {
                Authorization: `Bearer ${authToken}`,
            };

            axios
                .get(`${API_URL}/statistics/geolocation`, {
                    headers,
                })
                .then((response) => {
                    countPlaces(response.data.$values);
                    setIsLoadingStatistics(false);
                })
                .catch((error) => {
                    console.error("Ошибка при запросе статистики:", error);
                    setIsLoadingStatistics(false);
                });
        }
    }, [authToken, countPlaces]);

    const getCountryColorExpression = useCallback(() => {
        const expression = ["match", ["get", "iso_3166_1_alpha_3"]];

        if (stats.countryCodes && stats.countryCodes.length > 0) {
            stats.countryCodes.forEach((code) => {
                const countryPointsValue = countryPoints[code] || {
                    points: 0,
                    squarePoints: 1,
                };
                const percentage = calculateOpacityPercentage(
                    countryPointsValue.points,
                    countryPointsValue.squarePoints
                );
                let colorIndex = Math.min(
                    Math.floor(percentage / 10),
                    colorsLegend.length - 1
                );
                expression.push(code, colorsLegend[colorIndex]);
            });
        }

        expression.push("rgba(0, 0, 0, 0)");

        return expression;
    }, [stats.countryCodes, countryPoints]);

    const onLayerClick = useCallback(
        (event) => {
            try {
                if (
                    event.target &&
                    event.target.getLayer("countries-highlight")
                ) {
                    const features = event.target.queryRenderedFeatures(
                        event.point,
                        {
                            layers: ["countries-highlight"],
                        }
                    );

                    if (features.length > 0) {
                        const feature = features[0];
                        const countryCode =
                            feature.properties.iso_3166_1_alpha_3;

                        if (stats.countryCodes.includes(countryCode)) {
                            const countryPointsValue =
                                countryPoints[countryCode] || "Нет данных";
                            setPopupInfo({
                                longitude: event.lngLat.lng,
                                latitude: event.lngLat.lat,
                                points: countryPointsValue,
                            });
                        } else {
                            setPopupInfo(null);
                        }
                    } else {
                        setPopupInfo(null);
                    }
                }
            } catch (error) {
                console.error("Ошибка при запросе слоя:", error);
            }
        },
        [stats.countryCodes, countryPoints]
    );

    const onMapMouseMove = useCallback((event) => {
        try {
            if (event.target && event.target.getLayer("countries-highlight")) {
                const features = event.target.queryRenderedFeatures(
                    event.point,
                    {
                        layers: ["countries-highlight"],
                    }
                );

                if (features.length > 0) {
                    const feature = features[0];
                    setTooltipInfo({
                        longitude: event.lngLat.lng,
                        latitude: event.lngLat.lat,
                        countryName: feature.properties.name_en,
                    });
                } else {
                    setTooltipInfo(null);
                }
            }
        } catch (error) {
            console.error("Ошибка при запросе слоя:", error);
        }
    }, []);

    const handleViewStateChange = useCallback(
        (newViewState) => {
            if (
                newViewState.latitude !== viewState.latitude ||
                newViewState.longitude !== viewState.longitude ||
                newViewState.zoom !== viewState.zoom
            ) {
                setViewState(newViewState);
            }
        },
        [viewState]
    );

    const calculateOpacityPercentage = (points, squarePoints) => {
        return squarePoints > 0 ? Math.round((points / squarePoints) * 100) : 0;
    };

    return {
        user,
        stats,
        countryPoints,
        isLoadingStatistics,
        viewState,
        tooltipInfo,
        popupInfo,
        getCountryColorExpression,
        onLayerClick,
        onMapMouseMove,
        handleViewStateChange,
        setPopupInfo,
        setTooltipInfo,
    };
};
