import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Popup, Source, Layer, NavigationControl } from "react-map-gl";
import { useSelector } from "react-redux";

import { selectAuthToken } from "../../reducers/authReducer";
import { Layout, Loader } from "../../components";
import { Icon } from "../../shared/ui";
import usePluralize from "../../hooks/usePluralize";
import useAuthRedirect from "../../hooks/useAuthRedirect";

import { colorsLegend } from "../../constants/colors-for-map-label";

import MapComponent from "../../components/MapComponent/";

import "./../../styles/pages/profile-page.scss";
import "./../../styles/pages/statistic-page.scss";
import "./../../styles/components/mapbox_my.scss";

import { useProfile } from "./useProfile";

const Legend = () => (
  <div className="map-legend">
    <h3 className="map-legend__title">Открытие стран</h3>
    <ul className="map-legend__list">
      {colorsLegend.map((color, index) => {
        let rangeStart = index === 0 ? 0.01 : index * 10;
        let rangeEnd = (index + 1) * 10 - 0.01;

        let rangeStartStr =
          rangeStart > 1
            ? `${rangeStart.toFixed(0)}`
            : `${rangeStart.toFixed(2)}`;
        let rangeEndStr = `${rangeEnd.toFixed(2)}`;

        rangeEndStr = parseFloat(rangeEndStr).toFixed(2).replace(/\.00$/, "");

        return (
          <li key={index} className="map-legend__item">
            <span
              className="map-legend__color"
              style={{ backgroundColor: color }}
            ></span>
            <span className="map-legend__label">{`${rangeStartStr}% - ${rangeEndStr}%`}</span>
          </li>
        );
      })}
    </ul>
  </div>
);

export function ProfilePage() {
  const {
    user,
    stats,
    isLoadingStatistics,
    viewState,
    tooltipInfo,
    popupInfo,
    getCountryColorExpression,
    onLayerClick,
    onMapMouseMove,
    handleViewStateChange,
    setPopupInfo,
  } = useProfile();

  const authToken = useSelector(selectAuthToken);
  useAuthRedirect(authToken);

  const pluralize = usePluralize();
  const mapRef = useRef(null);
  const initialViewState = {
    center: [0, 0],
    zoom: 0.01,
    maxZoom: 2,
  };

  function formatCountryName(name) {
    return name.trim().toLowerCase().replace(/\s+/g, "-");
  }

  function formatPercentage(percent, digits) {
    const numericValue = Number(percent);
    if (isNaN(numericValue) || isNaN(digits) || digits < 0) {
      return "0";
    }

    const formattedValue = numericValue.toFixed(digits);
    const minValue = "0." + "0".repeat(digits - 1) + "1";

    return parseFloat(formattedValue) < parseFloat(minValue)
      ? minValue
      : formattedValue;
  }

  function importAll(r) {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "").replace(".svg", "")] = r(item);
    });
    return images;
  }

  const continentImages = importAll(
    require.context("../../icons/regions/continents", false, /\.svg$/)
  );

  const countryImages = importAll(
    require.context("../../icons/regions/countries", false, /\.svg$/)
  );

  const getCountryImageSrc = (countryCode) => {
    if (countryCode === "unknown" || !countryImages[countryCode]) {
      return null;
    }
    return countryImages[countryCode];
  };

  return (
    <Layout className={"profile"}>
      <h2>Профиль пользователя</h2>
      <div className="profile-info">
        <div className="profile-info__avatar">
          <Icon name="avatar-default" />
        </div>
        <div className="profile-info__user">
          <p className="profile-info__user-name">{user ? user.login : ""}</p>
          <p className="profile-info__user-from">Prague, Czech Republic</p>
          <ul className="profile-info__stat">
            <li className="profile-info__stat-item">
              <div className="profile-info__stat-num">
                {isLoadingStatistics ? (
                  <Loader size={"small"} type={"inline"} color={"light"} />
                ) : (
                  stats.continents
                )}
              </div>
              <p className="profile-info__stat-label">
                {pluralize(stats.continents, "континент")}
              </p>
            </li>
            <li className="profile-info__stat-item">
              <p className="profile-info__stat-num">
                {isLoadingStatistics ? (
                  <Loader size={"small"} type={"inline"} color={"light"} />
                ) : (
                  stats.countries
                )}
              </p>
              <p className="profile-info__stat-label">
                {pluralize(stats.countries, "страна")}
              </p>
            </li>
            <li className="profile-info__stat-item">
              <p className="profile-info__stat-num">
                {isLoadingStatistics ? (
                  <Loader size={"small"} type={"inline"} color={"light"} />
                ) : (
                  stats.cities
                )}
              </p>
              <p className="profile-info__stat-label">
                {pluralize(stats.cities, "город")}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="profile-map">
        <Legend />
        <div className="profile-map__wrap">
          <div className="profile-map__container">
            <MapComponent
              initialViewState={initialViewState}
              viewState={viewState}
              onViewStateChange={handleViewStateChange}
              maxZoom={2}
              ref={mapRef}
              scrollZoom={false}
              className={"map--1"}
              mapStyle="mapbox://styles/deivik/clpb9z7ng007z01r5etbbbcwn"
              renderWorldCopies={false}
              onClick={onLayerClick}
              onMouseMove={onMapMouseMove}
            >
              <NavigationControl showCompass={false} />
              <Source
                id="countries"
                type="vector"
                url="mapbox://mapbox.country-boundaries-v1"
              >
                <Layer
                  id="countries-highlight"
                  type="fill"
                  source="countries"
                  source-layer="country_boundaries"
                  paint={{
                    "fill-color": getCountryColorExpression(),
                  }}
                />
              </Source>
              {popupInfo && (
                <Popup
                  longitude={popupInfo.longitude}
                  latitude={popupInfo.latitude}
                  closeButton={true}
                  closeOnClick={false}
                  onClose={() => setPopupInfo(null)}
                  anchor="top"
                >
                  <div>Открыто точек: {popupInfo.points.points}</div>
                </Popup>
              )}

              {tooltipInfo && (
                <Popup
                  longitude={tooltipInfo.longitude}
                  latitude={tooltipInfo.latitude}
                  closeButton={false}
                  closeOnClick={false}
                  anchor="bottom"
                  className="mapboxgl-popup--title"
                >
                  <div>{tooltipInfo.countryName}</div>
                </Popup>
              )}
            </MapComponent>
          </div>
        </div>
      </div>
      <div className="stat">
        <h3 className="stat__title">Континенты</h3>
        <ul className="stat-list">
          {stats.continentNames.map((name, index) => (
            <li key={index} className="stat-list__item">
              <Link
                to={`/place/continent/${formatCountryName(name)}`}
                className="stat-list__link"
              >
                <span className="stat-list__item-img">
                  <span
                    className="stat-list__item-percentage"
                    style={{
                      height:
                        formatPercentage(stats.continentPercentages[name], 2) +
                        "%",
                    }}
                  ></span>
                  <img
                    src={continentImages[formatCountryName(name)]}
                    alt={name}
                  />
                  <span className="stat-list__item-count">
                    {stats.continentCountriesCount[name]}
                  </span>
                </span>
                <span className="stat-list__item-label">
                  {name}{" "}
                  <span
                    style={{
                      display: "block",
                      fontSize: "11px",
                    }}
                  >
                    {formatPercentage(stats.continentPercentages[name], 6)}%
                  </span>
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="stat">
        <h3 className="stat__title">Страны</h3>
        <ul className="stat-list">
          {stats.countryNames.map((name, index) => (
            <li key={index} className="stat-list__item">
              <Link to={`/place/country/${name}`}>
                <span className="stat-list__item-img stat-list__item-img--country">
                  <span
                    className="stat-list__item-percentage"
                    style={{
                      height:
                        formatPercentage(stats.countryPercentages[name], 2) +
                        "%",
                    }}
                  ></span>
                  <img
                    src={getCountryImageSrc(
                      formatCountryName(stats.countryCodes[index])
                    )}
                    alt={name}
                  />
                  {stats.countryCitiesCount[name] > 0 && (
                    <span className="stat-list__item-count">
                      {stats.countryCitiesCount[name]}
                    </span>
                  )}
                </span>
                <span className="stat-list__item-label">
                  {name}{" "}
                  <span
                    style={{
                      display: "block",
                      fontSize: "11px",
                    }}
                  >
                    {formatPercentage(stats.countryPercentages[name], 2)}%
                  </span>
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="stat">
        <h3 className="stat__title">Города</h3>
        <ul className="stat-list">
          {stats.cityNames.map((name, index) => (
            <li key={index} className="stat-list__item">
              <Link to={`/place/city/${name}`}>{name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
}

export default ProfilePage;
