import React, { useRef } from "react";
import useAuthRedirect from "../../hooks/useAuthRedirect";

import Layout from "../../components/Layout";
import ScreenshotComponent from "../../components/ScreenshotComponent";
import SidebarRight from "../../components/SidebarRight";
import SidebarRightBlock from "../../components/SidebarRightBlock";
import MapComponent from "../../components/MapComponent/";
import { Button } from "../../shared/ui";
import { useMap } from "./useMap";

import "./../../styles/pages/map-page.scss";
import "./../../styles/components/sidebar-right.scss";
import "./../../styles/components/modal.scss";
import "./../../styles/components/social-sharing.scss";
import "./../../styles/components/vendor/mapbox-gl.scss";

export default function MapNew() {
  const {
    authToken,
    viewState,
    modalIsOpen,
    isShowFlights,
    handleViewStateChange,
    fly2Country,
    handleShowFlightsChange,
    setModalIsOpen,
  } = useMap();

  useAuthRedirect(authToken);

  const mapRef = useRef(null);

  return (
    <>
      <Layout className={"map"}>
        <>
          <div className="map__wrap">
            <div className="map__block">
              <MapComponent
                viewState={viewState}
                ref={mapRef}
                onViewStateChange={handleViewStateChange}
                isWithCanvas
                className={"map--1"}
                useUserLocation
                shouldSaveMapStateToLocalStorage
                isGetDots={true}
                isShowFlights={isShowFlights}
              />
            </div>
            <SidebarRight>
              <SidebarRightBlock title={"Настройки карты"}>
                <label htmlFor="fly">
                  <input
                    type="checkbox"
                    name="fly"
                    id="fly"
                    checked={isShowFlights}
                    onChange={handleShowFlightsChange}
                  />{" "}
                  Показывать перелёты
                </label>
              </SidebarRightBlock>
              <SidebarRightBlock title={"Последние открытые страны:"}>
                <ul className="sidebar-right-list">
                  <li className="sidebar-right-list__item">
                    <span
                      onClick={() => fly2Country(mapRef, [-8.54195, 39.22798])}
                    >
                      Португалия
                    </span>
                  </li>
                  <li className="sidebar-right-list__item">
                    <span
                      onClick={() => fly2Country(mapRef, [15.42212, 49.87363])}
                    >
                      Чехия
                    </span>
                  </li>
                  <li className="sidebar-right-list__item">
                    <span onClick={() => fly2Country(mapRef, [10.0, 50.0], 0)}>
                      Европа
                    </span>
                  </li>
                </ul>
              </SidebarRightBlock>
              <SidebarRight>
                <SidebarRightBlock>
                  <Button
                    title={"Поделиться картой"}
                    onClick={() => setModalIsOpen(true)}
                  />
                </SidebarRightBlock>
              </SidebarRight>
            </SidebarRight>
          </div>
          {modalIsOpen ? (
            <ScreenshotComponent
              modalIsOpen={modalIsOpen}
              closeModal={() => setModalIsOpen(false)}
              isShowFlights={isShowFlights}
            />
          ) : null}
        </>
      </Layout>
    </>
  );
}
