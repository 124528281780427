import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const fetchData = async (type) => {
    switch (type) {
        case "country":
            return { name: "Страна", info: "Информация о стране" };
        case "city":
            return { name: "Город", info: "Информация о городе" };
        case "continent":
            return { name: "Континент", info: "Информация о континенте" };
        default:
            return { name: "Неизвестно", info: "Нет данных" };
    }
};

export const usePlace = () => {
    const { type, name } = useParams();
    const [placeData, setPlaceData] = useState(null);

    useEffect(() => {
        const loadPlaceData = async () => {
            const data = await fetchData(type, name);
            setPlaceData(data);
        };

        loadPlaceData();
    }, [type, name]);

    return {
        name,
        placeData,
    };
};
