export const setAuthToken = (token) => ({
  type: "SET_AUTH_TOKEN",
  payload: token,
});

export const clearAuthToken = () => ({
  type: "CLEAR_AUTH_TOKEN",
});

export const logoutUser = () => ({
  type: "LOGOUT_USER",
});

export const setUser = (user) => ({
  type: "SET_USER",
  user,
});
