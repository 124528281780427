import Joi from "joi";

export const loginValidationSchema = Joi.object({
  login: Joi.string()
    // .min(3)
    // .max(30)
    // .pattern(/^[a-zA-Z0-9_]+$/)
    .required()
    .messages({
      "string.empty": "Логин не может быть пустым",
      // "string.min": "Логин должен содержать минимум 3 символа",
      // "string.max": "Логин должен содержать максимум 30 символов",
      // "string.pattern.base":
      //   "Логин может содержать только буквы, цифры и знак подчеркивания",
    }),
  password: Joi.string()
    // .min(6)
    .required()
    .messages({
      "string.empty": "Пароль не может быть пустым",
      // "string.min": "Пароль должен содержать минимум 6 символов",
    }),
});
