import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider, useDispatch, useSelector } from "react-redux"; // Добавьте useDispatch
import store from "./store";
import { setAuthToken } from "./actions/authActions";
import { toggleSidebar } from "./actions/sidebarActions";

const authToken = localStorage.getItem("authToken");
if (authToken) {
  store.dispatch(setAuthToken(authToken));
}

const AppWrapper = () => {
  const isSidebarClosed = useSelector((state) => state.sidebar.isSidebarClosed);
  const dispatch = useDispatch();

  useEffect(() => {
    const savedState = localStorage.getItem("isSidebarClosed");
    if (savedState !== null) {
      dispatch(toggleSidebar(JSON.parse(savedState)));
    }
  }, [dispatch]);

  const wrapperClass = isSidebarClosed ? " wrap-sidebar--closed" : "";

  return (
    <div className={`wrap${wrapperClass}`}>
      <App />
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AppWrapper />
  </Provider>
);
