import React from "react";
import Loader from "../../../components/Loader";

const Button = ({
    onClick,
    title,
    type,
    disabled,
    className,
    style,
    tag = "span",
    buttonStyle,
    buttonSize,
    isWithLoader,
}) => {
    const Tag = tag;

    const buttonStyleClass = buttonStyle
        ? " button--t" + buttonStyle
        : " button--t1";

    const buttonSizeClass = buttonSize ? " button--s" + buttonSize : "";

    const customClassName = className ? ` ${className}` : "";

    const buttonLoaderClass = isWithLoader ? " button--loader" : "";

    return (
        <Tag
            className={`button${buttonStyleClass}${buttonSizeClass}${customClassName}${buttonLoaderClass}`}
            {...(style ? { style } : {})}
            onClick={onClick}
            disabled={disabled}
            {...(tag === "button" && { type: type || "button" })}
        >
            {isWithLoader && (
                <span className="button__loader">
                    <Loader size="small" type="inline" color="white" />
                </span>
            )}
            <span>{title}</span>
        </Tag>
    );
};

export default Button;
