import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { useStatistics } from "./useStatistics";
import { Tabs, SharingMessage } from "../../components";
import SharingComponent from "../../components/SharingComponent/SharingComponent";

const mockedStatTabsData = [
  { id: "tab1", title: "За всё время", content: <p>Содержимое таба 1</p> },
  { id: "tab2", title: "Год", content: <p>Содержимое таба 2</p> },
  { id: "tab3", title: "Месяц", content: <p>Содержимое таба 3</p> },
  { id: "tab4", title: "Неделя", content: <p>Содержимое таба 4</p> },
];

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "").replace(".svg", "")] = r(item);
  });
  return images;
}

const countryImages = importAll(
  require.context("../../icons/regions/countries", false, /\.svg$/)
);

function Statistics() {
  const {
    data,
    isShareModalOpen,
    setIsShareModalOpen,
    contentToShare,
    sharingTitle,
    sharingDescription,
    statRef,
    visitedCountriesRef,
    handleShare,
    continentsWithCountries,
    allCountries,
    renderHierarchy,
    isCountryVisited,
  } = useStatistics();

  const getCountryImageSrc = (countryCode) => {
    if (countryCode === "unknown" || !countryImages[countryCode]) {
      return null;
    }
    return countryImages[countryCode];
  };

  const visitedCountriesTabsData = useMemo(() => {
    if (!continentsWithCountries.length) return [];

    const allContinentsTab = {
      id: "all",
      title: "Все континенты",
      content: (
        <div className="stat-visitedCountries">
          <ul className="stat-visitedCountries__list">
            {allCountries.map((country) => (
              <li
                key={country.id}
                className={`stat-visitedCountries__item ${
                  isCountryVisited(country.code)
                    ? "stat-visitedCountries__item--checked"
                    : ""
                }`}
              >
                <span className="stat-visitedCountries__flag">
                  <img
                    src={getCountryImageSrc(country.code.toLowerCase())}
                    alt=""
                  />
                </span>
                {country.name}
              </li>
            ))}
          </ul>
        </div>
      ),
    };

    const continentTabs = continentsWithCountries.map((continent) => ({
      id: continent.code,
      title: continent.name,
      content: (
        <div className="stat-visitedCountries">
          <ul className="stat-visitedCountries__list">
            {continent.countries.map((country) => (
              <li
                key={country.id}
                className={`stat-visitedCountries__item ${
                  isCountryVisited(country.code)
                    ? "stat-visitedCountries__item--checked"
                    : ""
                }`}
              >
                <span className="stat-visitedCountries__flag">
                  <img
                    src={getCountryImageSrc(country.code.toLowerCase())}
                    alt=""
                  />
                </span>
                {country.name}
              </li>
            ))}
          </ul>
          {continent.countries.length > 0 && (
            <p className="stat__description">
              {
                continent.countries.filter((country) =>
                  isCountryVisited(country.code)
                ).length
              }{" "}
              из {continent.countries.length}. Это больше, чем у 95% наших
              пользователей. Вы ещё столько можете увидеть в {continent.name}.
              Организовать вашу идеальную поездку вам помогут наши{" "}
              <Link to={"/directions"}>готовые маршруты</Link>
            </p>
          )}
        </div>
      ),
    }));

    return [allContinentsTab, ...continentTabs];
  }, [continentsWithCountries, allCountries, isCountryVisited]);

  return (
    <Layout className={"stat"}>
      <div className="stat__container">
        <h2>Статистика</h2>
        <div ref={statRef}>
          <Tabs tabs={mockedStatTabsData} />
        </div>
        <SharingMessage
          label={"Поделиться статистикой"}
          onClick={() =>
            handleShare(
              statRef.current.innerHTML,
              "Моя статистика на WorldHunter",
              "Посмотрите на мою статистику путешествий!"
            )
          }
        />
      </div>
      <div className="stat__container">
        <h2>Посещённые страны</h2>
        <div ref={visitedCountriesRef}>
          <Tabs tabs={visitedCountriesTabsData} />
        </div>
        <SharingMessage
          label={"Поделиться статистикой посещённых стран"}
          onClick={() =>
            handleShare(
              visitedCountriesRef.current.innerHTML,
              "Страны, которые я посетил c WH",
              "Взгляните на карту стран, которые я уже посетил!"
            )
          }
        />
      </div>
      <SharingComponent
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        caption={sharingTitle}
        description={sharingDescription}
      >
        <div dangerouslySetInnerHTML={{ __html: contentToShare }} />
      </SharingComponent>

      {!data && <Loader size={"small"} color={"light"} type={"inline"} />}

      {data ? (
        <div className="country">
          {data.length > 0 ? renderHierarchy(data) : <p>Данные отсутствуют</p>}
          <h3>Все данные</h3>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      ) : (
        <Loader size={"small"} color={"light"} type={"inline"} />
      )}
    </Layout>
  );
}

export default Statistics;
