import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";

const Layout = ({ children, className }) => {
  return (
    <>
      <Sidebar />
      <div className={`wrap-content ${className || ""}`}>
        <Header />
        <main className="main">{children}</main>
      </div>
    </>
  );
};

export default Layout;
