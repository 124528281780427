import { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { API_URL } from "../../constants/variables";
import { selectAuthToken } from "../../reducers/authReducer";
import useAuthRedirect from "../../hooks/useAuthRedirect";

export const useStatistics = () => {
  const [data, setData] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [contentToShare, setContentToShare] = useState(null);
  const [sharingTitle, setSharingTitle] = useState("");
  const [sharingDescription, setSharingDescription] = useState("");
  const [countriesData, setCountriesData] = useState(null);
  const [visitedCountries, setVisitedCountries] = useState([]);

  const authToken = useSelector(selectAuthToken);
  const statRef = useRef(null);
  const visitedCountriesRef = useRef(null);

  useAuthRedirect(authToken);

  useEffect(() => {
    if (authToken) {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      axios
        .get(`${API_URL}/statistics/geolocation`, {
          headers,
        })
        .then((response) => {
          setData(response.data.$values);
          const visited = extractVisitedCountryCodes(response.data.$values);
          setVisitedCountries(visited);
        })
        .catch((error) => {
          console.error("Ошибка при запросе статистики:", error);
        });
    }
  }, [authToken]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${API_URL}/Countries`);
        setCountriesData(response.data.$values);
      } catch (error) {
        console.error("Error fetching countries data:", error);
      }
    };

    fetchCountries();
  }, []);

  const extractVisitedCountryCodes = (data) => {
    const visited = [];
    const extractCodes = (items) => {
      items.forEach((item) => {
        if (item.countryPointsList && item.countryPointsList.code) {
          visited.push(item.countryPointsList.code);
        }
        if (item.items && item.items.$values) {
          extractCodes(item.items.$values);
        }
      });
    };
    extractCodes(data);
    return visited;
  };

  const isCountryVisited = (countryCode) => {
    return visitedCountries.includes(countryCode);
  };

  const calculateOpenedPercent = (item) => {
    const percent =
      item.countryPointsList.squarePoints > 0
        ? (item.points / item.countryPointsList.squarePoints) * 100
        : 0;
    return percent < 0.01 ? 0.01 : percent.toFixed(2);
  };

  const handleShare = (content, title, description) => {
    setContentToShare(content);
    setSharingTitle(title);
    setSharingDescription(description);
    setIsShareModalOpen(true);
  };

  const continentsWithCountries = useMemo(() => {
    if (!countriesData) return [];

    const world = countriesData.find((item) => item.type === 1);
    if (!world) return [];

    const continents = countriesData.filter(
      (item) => item.type === 2 && item.code !== "WO"
    );

    return continents.map((continent) => {
      const countries = countriesData.filter(
        (item) => item.type === 3 && item.parentId === continent.id
      );

      return {
        ...continent,
        countries: countries,
      };
    });
  }, [countriesData]);

  const allCountries = useMemo(() => {
    if (!countriesData) return [];
    return countriesData.filter(
      (item) => item.type === 3 && item.code !== "WO"
    );
  }, [countriesData]);

  const renderHierarchy = (items) => {
    return items.map((item) => {
      if (item.countryPointsList.name === "unknown") {
        return null;
      }

      const openedPercent = calculateOpenedPercent(item);

      return (
        <div key={item.id} className="stat-temp-list">
          <h3>{item.countryPointsList.name}</h3>
          <p>Количество точек: {item.points}</p>
          <p>Открыто: {openedPercent}%</p>
          {item.items &&
            item.items.$values &&
            item.items.$values.length > 0 && (
              <div className="stat-temp-list__item">
                {renderHierarchy(item.items.$values)}
              </div>
            )}
        </div>
      );
    });
  };

  return {
    data,
    calculateOpenedPercent,
    isShareModalOpen,
    setIsShareModalOpen,
    contentToShare,
    sharingTitle,
    sharingDescription,
    countriesData,
    statRef,
    visitedCountriesRef,
    handleShare,
    continentsWithCountries,
    allCountries,
    renderHierarchy,
    isCountryVisited,
  };
};
