import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactModal from "react-modal";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import useScreenshot from "../../hooks/useScreenshot";
import { Button } from "../../shared/ui";
import Loader from "../Loader";
import "./../../styles/components/SharingComponent.scss";

const SharingComponent = ({
  isOpen,
  onClose,
  caption,
  description,
  children,
}) => {
  const [screenshotUrl, setScreenshotUrl] = useState(null);
  const [isTakingScreenshot, setIsTakingScreenshot] = useState(false);
  const contentRef = useRef(null);
  const { takeScreenshot } = useScreenshot();

  ReactModal.setAppElement("#root");

  const handleTakeScreenshot = useCallback(async () => {
    setIsTakingScreenshot(true);
    try {
      const screenshot = await takeScreenshot(contentRef);
      setScreenshotUrl(screenshot);
    } catch (error) {
      console.error("Ошибка при создании скриншота:", error);
    } finally {
      setIsTakingScreenshot(false);
    }
  }, [takeScreenshot]);

  useEffect(() => {
    if (isOpen) {
      handleTakeScreenshot();
    }
  }, [handleTakeScreenshot, isOpen]);

  const downloadScreenshot = () => {
    const link = document.createElement("a");
    link.href = screenshotUrl;
    link.download = "WorldHunter.com-screenshot.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="screenshotContainer" ref={contentRef}>
        <h2>{caption}</h2>
        {children}
        <p>{description}</p>
      </div>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        className={"modal"}
        overlayClassName={"modal__overlay"}
      >
        <span className="modal__close" onClick={onClose}>
          х
        </span>
        <h2>Поделиться скриншотом</h2>
        <div className="socialSharingBlock">
          {isTakingScreenshot ? (
            <div className="socialSharingBlock__loader">
              <Loader size={"big"} />
            </div>
          ) : screenshotUrl ? (
            <>
              <div className="socialSharingBlock__preview">
                <img src={screenshotUrl} alt="Скриншот блока" />
              </div>
              <div className="socialSharingBlock__sharing">
                <FacebookShareButton url={screenshotUrl}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={screenshotUrl}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <TelegramShareButton url={screenshotUrl}>
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                <WhatsappShareButton url={screenshotUrl}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <EmailShareButton url={screenshotUrl}>
                  <EmailIcon size={32} round />
                </EmailShareButton>
                <Button
                  buttonStyle={2}
                  title={"Сохранить"}
                  onClick={downloadScreenshot}
                />
              </div>
            </>
          ) : null}
        </div>
      </ReactModal>
    </>
  );
};

export default SharingComponent;
