import { TOGGLE_SIDEBAR } from '../actions/sidebarActions';

const initialState = {
    isSidebarClosed: false,
};

const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
        return {
            ...state,
            isSidebarClosed: !state.isSidebarClosed,
        };
        default:
        return state;
    }
};

export default sidebarReducer;
