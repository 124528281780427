import React from "react";
import { Icon } from "../../shared/ui";

import "./../../styles/components/SharingMessage.scss";

const SharingMessage = ({ label, onClick }) => {
  return (
    <div className="SharingMessage" onClick={onClick}>
      <span className="SharingMessage__icon">
        <Icon name="share" />
      </span>
      <span className="SharingMessage__label">{label}</span>
    </div>
  );
};

export default SharingMessage;
