import { useSelector } from "react-redux";
import { selectAuthToken } from "../../reducers/authReducer";
import useAuthRedirect from "../../hooks/useAuthRedirect";

export const useSettings = () => {
    const authToken = useSelector(selectAuthToken);
    const user = useSelector((state) => state.auth.user);

    useAuthRedirect(authToken);

    return {
        user,
    };
};
