import React from "react";

function Input({ register, name, type = "text", placeholder, label, error }) {
    return (
        <div className="form-element">
            {label && <label className="form-label">{label}</label>}
            <div className="input">
                <input
                    {...register(name)}
                    type={type}
                    autoComplete={"off"}
                    placeholder={placeholder}
                />
            </div>
            {error && <p className="form-error">{error}</p>}
        </div>
    );
}

export default Input;
