import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import ProfilePage from "./pages/Profile";
import SettingsPage from "./pages/Settings";
// import MapOldPage from "./pages/MapOld";
import MapPage from "./pages/Map";
import MapAdminPage from "./pages/MapAdmin";
import RegistrationPage from "./pages/Registration";
import LoginPage from "./pages/Login";
import StatisticsPage from "./pages/Statistics";
import PlacePage from "./pages/Place";
import DirectionsPage from "./pages/Directions/Directions";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/statistics" element={<StatisticsPage />} />
                {/* <Route path="/map-old" element={<MapOldPage />} /> */}
                <Route path="/map" element={<MapPage />} />
                <Route path="/map-admin" element={<MapAdminPage />} />
                <Route path="/registration" element={<RegistrationPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/place/:type/:name" element={<PlacePage />} />
                <Route path="/directions" element={<DirectionsPage />} />
            </Routes>
        </Router>
    );
}

export default App;
