import { API_URL } from "./../constants/variables";

export const FETCH_DRAW_RADII_START = "FETCH_DRAW_RADII_START";
export const FETCH_DRAW_RADII_SUCCESS = "FETCH_DRAW_RADII_SUCCESS";
export const FETCH_DRAW_RADII_FAILURE = "FETCH_DRAW_RADII_FAILURE";

export const fetchDrawRadii = () => async (dispatch) => {
  dispatch({ type: FETCH_DRAW_RADII_START });
  try {
    const response = await fetch(`${API_URL}/Settings?query=DrawRadius`);
    const data = await response.json();

    if (!data.$values) {
      throw new Error("Invalid response format: $values property not found");
    }

    const radii = data.$values.reduce((acc, item) => {
      const zoomLevel = item.code.match(/\d+$/)[0];
      acc[zoomLevel] = parseInt(item.value);
      return acc;
    }, {});

    dispatch({ type: FETCH_DRAW_RADII_SUCCESS, payload: radii });
  } catch (error) {
    console.error("Error fetching draw radii:", error);
    dispatch({ type: FETCH_DRAW_RADII_FAILURE, payload: error });
  }
};
