import { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAuthToken } from "../../reducers/authReducer";
import { fetchDrawRadii } from "../../actions/mapActions";

export const useMap = () => {
    const authToken = useSelector(selectAuthToken);
    const dispatch = useDispatch();

    const minZoomDefault = 0.5;

    const [viewState, setViewState] = useState({
        latitude: 0,
        longitude: Math.random() * 360 - 180,
        zoom: minZoomDefault,
    });

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isShowFlights, setIsShowFlights] = useState(true);

    useEffect(() => {
        dispatch(fetchDrawRadii());
    }, [dispatch]);

    const handleViewStateChange = useCallback(
        (newViewState) => {
            if (
                newViewState.latitude !== viewState.latitude ||
                newViewState.longitude !== viewState.longitude ||
                newViewState.zoom !== viewState.zoom
            ) {
                setViewState(newViewState);
            }
        },
        [viewState]
    );

    const fly2Country = (mapRef, coordsToCenter, zoom = null) => {
        const targetZoom = zoom !== null ? zoom : viewState.zoom;
        mapRef.current
            .getMap()
            .flyTo({ center: coordsToCenter, zoom: targetZoom, speed: 2 });
    };

    const handleShowFlightsChange = (e) => {
        setIsShowFlights(e.target.checked);
    };

    return {
        authToken,
        minZoomDefault,
        viewState,
        modalIsOpen,
        isShowFlights,
        handleViewStateChange,
        fly2Country,
        handleShowFlightsChange,
        setModalIsOpen,
    };
};
