import React from "react";

import "./../styles/components/loader.scss";

const Loader = (prop) => {
  const { size, type, color, fullscreen } = prop;

  return (
    <div
      className={`loader loader--${size} ${type ? `loader--${type}` : ""} ${
        color ? `loader--${color}` : ""
      } ${fullscreen ? "loader--fullscreen" : ""}`}
    >
      <div className="loader__container">
        <div className="loader__spinner"></div>
      </div>
    </div>
  );
};

export default Loader;
