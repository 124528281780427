import React from "react";

import Icon from "../shared/ui/Icon/Icon";

import "./../styles/components/searchbar.scss";

function SearchBar() {
  return (
    <>
      <div className="searchBar">
        <div className="input">
          <Icon name="search" />
          <input type="text" placeholder="Search" />
        </div>
      </div>
    </>
  );
}

export default SearchBar;
