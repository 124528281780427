import React, {
  forwardRef,
  useRef,
  useEffect,
  useCallback,
  useState,
} from "react";
import { useSelector } from "react-redux";
import ReactModal from "react-modal";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import { Source, Layer } from "react-map-gl";

import { fetchData } from "../functions";
import {
  AFRICA_COORDS,
  ANTARCTICA_COORDS,
  ASIA_COORDS,
  AUSTRALIA_COORDS,
  EUROPE_COORDS,
  NORTH_AMERICA_COORDS,
  SOUTH_AMERICA_COORDS,
  WORLD_COORDS,
} from "./../constants/continents-for-sharing";
import axios from "axios";
import { API_URL } from "../constants/variables";
import { colorsLegend } from "../constants/colors-for-map-label";

import { selectAuthToken } from "../reducers/authReducer";

import useScreenshot from "../hooks/useScreenshot";

import MapComponent from "../components/MapComponent/";
import Loader from "../components/Loader";

import { Button, SelectComponent } from "../shared/ui";

const ScreenshotComponent = forwardRef(
  ({ modalIsOpen, closeModal, isShowFlights }, ref) => {
    const authToken = useSelector(selectAuthToken);
    const mapForScreenshotRef = useRef(null);
    const mapContainerForScreenshotRef = useRef(null);

    const [viewStateForScreenShot, setViewStateForScreenShot] = useState({
      latitude: 0,
      longitude: 0,
      zoom: 0,
    });

    const [screenshotUrl, setScreenshotUrl] = useState(null);
    const [isMapReadyForScreenshot, setIsMapReadyForScreenshot] =
      useState(false);
    const [isTakingScreenshot, setIsTakingScreenshot] = useState(false);
    const [isDataForTYPE1Fetched, setIsDataForTYPE1Fetched] = useState(false);
    const [selectedTypeValue, setSelectedTypeValue] = useState(null);
    const [selectedScaleValue, setSelectedScaleValue] = useState(null);
    const [mapProjectionForScreenshot, setMapProjectionForScreenshot] =
      useState("mercator");
    const [drawRadiusForScreenshot, setDrawRadiusForScreenshot] = useState(1);
    const [dataForScreenshot, setDataForScreenshot] = useState(null);
    const [canvasUpdater, setCanvasUpdater] = useState(0);
    const [isCanvasRendered, setIsCanvasRendered] = useState(false);
    const [countryData, setCountryData] = useState(null);

    const { takeScreenshot } = useScreenshot();

    ReactModal.setAppElement("#root");
    const handleTakeScreenshot = useCallback(async () => {
      try {
        const screenshot = await takeScreenshot(mapContainerForScreenshotRef);
        setScreenshotUrl(screenshot);
      } catch (error) {
        console.error("Ошибка при создании скриншота:", error);
      }
    }, [takeScreenshot]);

    console.log("4");

    const downloadScreenshot = () => {
      const link = document.createElement("a");
      link.href = screenshotUrl;
      link.download = "WorldHunter.com-screenshot.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const optionsType = [
      { value: "option1", label: "Открытие мира" },
      { value: "option2", label: "Посещённые страны" },
    ];

    const optionsScale = [
      { value: "option1", label: "Весь мир" },
      { value: "option2", label: "Европа" },
      { value: "option3", label: "Азия" },
      { value: "option4", label: "Северная Америка" },
      { value: "option5", label: "Южная Америка" },
      { value: "option6", label: "Австралия" },
      { value: "option7", label: "Африка" },
      { value: "option8", label: "Антарктида" },
    ];

    const handleTypeChange = (selectedOption) => {
      setSelectedTypeValue(selectedOption);
      setIsTakingScreenshot(true);
      setCanvasUpdater((v) => v + 1);
      setIsDataForTYPE1Fetched(false);
    };

    const handleScaleChange = (selectedOption) => {
      setSelectedScaleValue(selectedOption);
      setIsTakingScreenshot(true);
      updateMapForScreenshot(selectedOption.value);
      setCanvasUpdater((v) => v + 1);
    };

    /*
      делаем скриншот, если:
      1. Выбран пункт "Открытие мира" и при этом данные для отображения открытия карты (точки) уже загружены
      2. Карта отрендерилась и готова
      3. Оба селекта ("Тип карты" и "Масштаб карты") выбраны
    */
    useEffect(() => {
      if (
        selectedTypeValue &&
        selectedScaleValue &&
        isTakingScreenshot &&
        isCanvasRendered &&
        (selectedTypeValue.value !== "option1" || isDataForTYPE1Fetched)
      ) {
        setTimeout(() => {
          handleTakeScreenshot().then(() => {
            setIsTakingScreenshot(false);
          });
        }, 1500);
      }
    }, [
      selectedTypeValue,
      selectedScaleValue,
      isTakingScreenshot,
      handleTakeScreenshot,
      isMapReadyForScreenshot,
      isDataForTYPE1Fetched,
      isCanvasRendered,
    ]);

    useEffect(() => {
      if (
        modalIsOpen &&
        selectedTypeValue &&
        selectedTypeValue.value === "option1"
      ) {
        fetchData(0, 0, 0)
          .then((zoomData) => {
            if (zoomData[0] && Array.isArray(zoomData[0].CoordinatesArray)) {
              setDataForScreenshot(zoomData[0].CoordinatesArray);
              setIsDataForTYPE1Fetched(true);
            } else {
              console.error("Invalid data format for zoom 0", zoomData);
            }
          })
          .catch((error) => {
            console.log(
              "Error message:",
              error.message,
              "Error object:",
              error
            );
          });
      }
    }, [modalIsOpen, selectedTypeValue]);

    const processCountryData = useCallback((items) => {
      let countries = 0;
      let countryNames = new Set();
      let countryCodes = new Set();
      let countryPointsMap = {};
      let countryPercentages = {};

      const traverseItems = (items, country = null) => {
        items.forEach((item) => {
          const name = item.countryPointsList.name;
          const type = item.type;
          const points = item.points;
          const squarePoints = item.countryPointsList.squarePoints;
          const code = item.countryPointsList.code;

          switch (type) {
            case "Country":
              countries++;
              countryNames.add(name);
              country = name;
              countryCodes.add(code);
              countryPointsMap[code] = { points, squarePoints };
              countryPercentages[name] = calculatePercentage(
                points,
                squarePoints
              );
              break;
            default:
              break;
          }
          if (item.items && Array.isArray(item.items.$values)) {
            traverseItems(item.items.$values, country);
          }
        });
      };

      traverseItems(items);

      return {
        countries,
        countryNames: Array.from(countryNames),
        countryCodes: Array.from(countryCodes),
        countryPointsMap,
        countryPercentages,
      };
    }, []);

    const fetchCountryData = useCallback(async () => {
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const response = await axios.get(`${API_URL}/statistics/geolocation`, {
          headers,
        });

        return processCountryData(response.data.$values);
      } catch (error) {
        console.error("Ошибка при запросе статистики:", error);
        return null;
      }
    }, [authToken, processCountryData]);

    useEffect(() => {
      if (selectedTypeValue?.value === "option2") {
        fetchCountryData()
          .then((data) => setCountryData(data))
          .catch((error) => console.error(error));
      }
    }, [fetchCountryData, selectedTypeValue]);

    const calculatePercentage = (points, squarePoints) => {
      return squarePoints > 0 ? ((points / squarePoints) * 100).toFixed(5) : 0;
    };

    const calculateOpacityPercentage = (points, squarePoints) => {
      return squarePoints > 0 ? Math.round((points / squarePoints) * 100) : 0;
    };

    const getCountryColorExpression = () => {
      const expression = ["match", ["get", "iso_3166_1_alpha_3"]];

      if (countryData && countryData.countryCodes) {
        countryData.countryCodes.forEach((code) => {
          const countryPointsValue = countryData.countryPointsMap[code] || {
            points: 0,
            squarePoints: 1,
          };
          const percentage = calculateOpacityPercentage(
            countryPointsValue.points,
            countryPointsValue.squarePoints
          );
          let colorIndex = Math.min(
            Math.floor(percentage / 10),
            colorsLegend.length - 1
          );
          expression.push(code, colorsLegend[colorIndex]);
        });
      }

      expression.push("rgba(0, 0, 0, 0)");

      return expression;
    };

    const updateMapForScreenshot = (scaleOption) => {
      let coords;

      switch (scaleOption) {
        case "option2":
          coords = EUROPE_COORDS;
          break;
        case "option3":
          coords = ASIA_COORDS;
          break;
        case "option4":
          coords = NORTH_AMERICA_COORDS;
          break;
        case "option5":
          coords = SOUTH_AMERICA_COORDS;
          break;
        case "option6":
          coords = AUSTRALIA_COORDS;
          break;
        case "option7":
          coords = AFRICA_COORDS;
          break;
        case "option8":
          coords = ANTARCTICA_COORDS;
          break;
        default:
          coords = WORLD_COORDS;
      }

      setViewStateForScreenShot(coords);
      setMapProjectionForScreenshot(coords.projection);
      setDrawRadiusForScreenshot(coords.drawRadius);
    };

    const handleViewStateChange = useCallback(
      (newViewState) => {
        if (
          newViewState.latitude !== viewStateForScreenShot.latitude ||
          newViewState.longitude !== viewStateForScreenShot.longitude ||
          newViewState.zoom !== viewStateForScreenShot.zoom
        ) {
          setViewStateForScreenShot(newViewState);
        }
      },
      [viewStateForScreenShot]
    );

    const handleMapLoadedAndReadyChange = useCallback((isReady) => {
      setIsMapReadyForScreenshot(isReady);
    }, []);

    const OpenWorldMapComponent = () => (
      <MapComponent
        viewState={viewStateForScreenShot}
        maxZoom={0}
        renderWorldCopies={false}
        ref={mapForScreenshotRef}
        projection={mapProjectionForScreenshot}
        scrollZoom={false}
        preserveDrawingBuffer={true}
        isWithCanvas={true}
        className={"map--1"}
        customData={dataForScreenshot}
        customDrawRadius={drawRadiusForScreenshot}
        onViewStateChange={handleViewStateChange}
        canvasUpdater={canvasUpdater}
        isGetDots={true}
        isShowFlights={isShowFlights}
        onMapLoadedAndReadyChange={handleMapLoadedAndReadyChange}
        onCanvasRendered={setIsCanvasRendered}
      />
    );

    const VisitedCountriesMapComponent = () => (
      <MapComponent
        viewState={viewStateForScreenShot}
        maxZoom={0}
        renderWorldCopies={false}
        ref={mapForScreenshotRef}
        projection={mapProjectionForScreenshot}
        scrollZoom={false}
        preserveDrawingBuffer={true}
        className={"map--1"}
        mapStyle="mapbox://styles/deivik/clpb9z7ng007z01r5etbbbcwn"
        onViewStateChange={handleViewStateChange}
      >
        {countryData && (
          <Source
            id="countries"
            type="vector"
            url="mapbox://mapbox.country-boundaries-v1"
          >
            <Layer
              id="countries-highlight"
              type="fill"
              source="countries"
              source-layer="country_boundaries"
              paint={{
                "fill-color": getCountryColorExpression(),
              }}
            />
          </Source>
        )}
      </MapComponent>
    );

    return (
      <>
        <div className="screen" ref={mapContainerForScreenshotRef}>
          <div className="screen__container">
            {selectedTypeValue?.value === "option1" ? (
              <OpenWorldMapComponent />
            ) : selectedTypeValue?.value === "option2" ? (
              <VisitedCountriesMapComponent />
            ) : null}
          </div>
        </div>
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={() => closeModal(false)}
          className={"modal"}
          overlayClassName={"modal__overlay"}
        >
          <span className="modal__close" onClick={closeModal}>
            х
          </span>
          <h2>Поделиться своей картой:</h2>
          <div className="socialSharingBlock">
            <div className="socialSharingBlock__row">
              <div className="socialSharingBlock__item">
                <SelectComponent
                  placeholder="Тип карты"
                  options={optionsType}
                  onChange={handleTypeChange}
                />
              </div>
              <div className="socialSharingBlock__item">
                <SelectComponent
                  placeholder="Масштаб"
                  options={optionsScale}
                  onChange={handleScaleChange}
                />
              </div>
            </div>
            {selectedTypeValue &&
            selectedScaleValue &&
            isTakingScreenshot &&
            !screenshotUrl ? (
              <div className="socialSharingBlock__row socialSharingBlock__row--loader">
                <Loader size={"big"} />
              </div>
            ) : (
              screenshotUrl && (
                <>
                  <div className="socialSharingBlock__row">
                    <div className="socialSharingBlock__item">
                      <div className="socialSharingBlock__image">
                        {isTakingScreenshot ? (
                          <Loader size={"big"} />
                        ) : (
                          screenshotUrl && (
                            <img src={screenshotUrl} alt="Скриншот блока" />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="socialSharingBlock__row socialSharingBlock__row--start">
                    <div className="socialSharingBlock__item">
                      <FacebookShareButton url={screenshotUrl} quote={"title"}>
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    </div>
                    <div className="socialSharingBlock__item">
                      <TwitterShareButton url={screenshotUrl} title={"title"}>
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                    </div>
                    <div className="socialSharingBlock__item">
                      <TelegramShareButton url={screenshotUrl} title={"title"}>
                        <TelegramIcon size={32} round />
                      </TelegramShareButton>
                    </div>
                    <div className="socialSharingBlock__item">
                      <WhatsappShareButton url={screenshotUrl} title={"title"}>
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                    </div>
                    <div className="socialSharingBlock__item">
                      <EmailShareButton url={screenshotUrl} title={"title"}>
                        <EmailIcon size={32} round />
                      </EmailShareButton>
                    </div>
                    <div className="socialSharingBlock__item">или</div>
                    <div className="socialSharingBlock__item">
                      <Button
                        buttonStyle={2}
                        title={"Сохранить"}
                        onClick={downloadScreenshot}
                      />
                    </div>
                    <div className="socialSharingBlock__item">
                      изображение на устройство
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </ReactModal>
      </>
    );
  }
);

export default ScreenshotComponent;
