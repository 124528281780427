import pako from "pako";
import { API_URL } from "./constants/variables";
import KDTree from "kd-tree-javascript";

export async function fetchData(latitude, longitude, mapScale = 13) {
  const authToken = localStorage.getItem("authToken");
  const url = new URL(`${API_URL}/GeoLocations`);
  url.searchParams.append("Latitude", latitude.toFixed(6));
  url.searchParams.append("Longitude", longitude.toFixed(6));
  url.searchParams.append("MapScale", mapScale.toFixed(0));

  const headers = {
    Authorization: `Bearer ${authToken}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(url.toString(), { headers });

    if (!response.ok) {
      console.error("Ошибка при получении данных");
      throw new Error("Ошибка при получении данных");
    }

    const base64String = await response.text();
    const binaryString = atob(base64String);
    const charList = binaryString.split("").map((c) => c.charCodeAt(0));
    const byteArray = new Uint8Array(charList);
    const decompressedArray = pako.inflate(byteArray);
    const decompressedString = new TextDecoder("utf-8").decode(
      decompressedArray
    );
    const data = JSON.parse(decompressedString);

    if (!Array.isArray(data)) {
      throw new Error("Invalid response data, expected an array");
    }

    const zoomData = data.reduce((acc, item) => {
      if (Array.isArray(item.Geolocations)) {
        const CoordinatesArray = item.Geolocations.map((geoItem) => [
          geoItem.Lon,
          geoItem.Lat,
          geoItem.Altitude,
          geoItem.Speed,
          geoItem.Direction,
        ]);

        const {
          InnerRadius = 0,
          MapScale,
          OuterRadius = 0,
          DrawRadius = 0,
        } = item;

        // Фильтрация точек
        const filteredCoordinates = filterPointsWithKDTree(CoordinatesArray);

        return {
          ...acc,
          [MapScale]: {
            InnerRadius,
            OuterRadius,
            DrawRadius,
            CoordinatesArray: filteredCoordinates,
          },
        };
      } else {
        throw new Error("Geolocations is not an array");
      }
    }, {});

    return zoomData;
  } catch (error) {
    console.error("Ошибка при обработке данных:", error);
    throw error;
  }
}

// расстояние между двумя точками в метрах
export function getDistance(lat1, lon1, lat2, lon2) {
  var R = 6371e3;
  var φ1 = (lat1 * Math.PI) / 180;
  var φ2 = (lat2 * Math.PI) / 180;
  var Δφ = ((lat2 - lat1) * Math.PI) / 180;
  var Δλ = ((lon2 - lon1) * Math.PI) / 180;

  var a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  var d = R * c;
  return parseInt(d);
}

// Фильтрация точек с использованием k-d дерева
export function filterPointsWithKDTree(data) {
  console.log("Общее количество точек до фильтрации:", data.length);
  const startTime = performance.now(); // Начало замера времени

  // Функция для расчета евклидова расстояния для k-d дерева
  function euclideanDistance(a, b) {
    const latDist = a[1] - b[1];
    const lonDist = a[0] - b[0];
    return Math.sqrt(latDist * latDist + lonDist * lonDist);
  }

  // Создание k-d дерева на основе координат
  const tree = new KDTree.kdTree(data, euclideanDistance, [0, 1]);

  // Фильтрация точек, удаляем те, что находятся ближе 30 метров
  const filteredData = [];
  data.forEach((point) => {
    const nearest = tree.nearest(point, 2, 0.00027); // 0.00027 - это примерно 30 метров для широты/долготы
    if (nearest.length === 1) {
      filteredData.push(point);
    }
  });

  const endTime = performance.now(); // Окончание замера времени
  const duration = ((endTime - startTime) / 1000).toFixed(2); // Время выполнения в секундах

  console.log("Общее количество точек после фильтрации:", filteredData.length);
  console.log(`Фильтрация заняла: ${duration} секунд`);

  return filteredData;
}

export const updateSettings = async (code, value) => {
  try {
    const response = await fetch(`${API_URL}/Settings/${code}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ value }),
    });

    if (!response.ok) {
      throw new Error(`Could not update settings for code ${code}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error(error);
  }
};

export function updateMapSizes(mapRef) {
  if (mapRef.current) {
    const map = mapRef.current.getMap();

    const width = map.getCanvas().clientWidth;
    const height = map.getCanvas().clientHeight;

    const left = map.unproject([0, height / 2]);
    const right = map.unproject([width, height / 2]);
    const top = map.unproject([width / 2, 0]);
    const bottom = map.unproject([width / 2, height]);

    const verticalSize = getDistance(top.lat, top.lng, bottom.lat, bottom.lng);
    const horizontalSize = getDistance(
      left.lat,
      left.lng,
      right.lat,
      right.lng
    );

    return [{ verticalSize }, { horizontalSize }];
  }
}

export function deb(text) {
  console.log("---------\n\r", text, "\n\r---------");
}

// Функция для определения, является ли смещение значительным
export const isSignificantMove = (
  currentCenter,
  prevCenter,
  thresholdPercent
) => {
  if (!prevCenter || !currentCenter) return true;

  const latDiff = Math.abs(currentCenter.lat - prevCenter.lat);
  const lngDiff = Math.abs(currentCenter.lon - prevCenter.lon);

  // Расчет размеров карты в градусах
  const bounds = currentCenter.map.getBounds();
  const width = Math.abs(bounds._ne.lng - bounds._sw.lng);
  const height = Math.abs(bounds._ne.lat - bounds._sw.lat);

  // Расчет пороговых значений для смещения
  const latThreshold = height * (thresholdPercent / 100);
  const lngThreshold = width * (thresholdPercent / 100);

  // Проверяем, превышает ли смещение пороговые значения
  return latDiff > latThreshold || lngDiff > lngThreshold;
};
