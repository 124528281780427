import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthToken } from "../reducers/authReducer";

import SearchBar from "./SearchBar";

import "./../styles/base.scss";
import "./../styles/components/fix.scss";
import "./../styles/components/typography.scss";
import "./../styles/components/header.scss";
import "./../styles/components/buttons.scss";

function Header() {
  const authToken = useSelector(selectAuthToken);

  return (
    <header className="header">
      <div className="header__wrap">
        <div className="header__left">
          <SearchBar />
          <ul className="header-menu">
            <li className="header-menu__item">
              <NavLink to="/faq" className="header-menu__link">
                FAQ
              </NavLink>
            </li>
            <li className="header-menu__item">
              <NavLink to="/help" className="header-menu__link">
                HELP
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="header__right">
          {!authToken ? (
            <>
              <div className="header-buttons">
                <Link to="/login" className="button button--s1 button--t2">
                  Авторизация
                </Link>
                <Link
                  to="/registration"
                  className="button button--s1 button--t1"
                >
                  Регистрация
                </Link>
              </div>
            </>
          ) : null}
          <div className="header-user"></div>
        </div>
      </div>
    </header>
  );
}

export default Header;
