import React from "react";
import Layout from "../../components/Layout";
import { usePlace } from "./usePlace";

function PlacePage() {
    const { name, placeData } = usePlace();

    if (!placeData) return <div>Loading...</div>;

    return (
        <>
            <Layout>
                <h1>{name}</h1>
                <h2>{placeData.name}</h2>
                <p>{placeData.info}</p>
            </Layout>
        </>
    );
}

export default PlacePage;
