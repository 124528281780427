import React from "react";
import Layout from "../../components/Layout";
import { Button, Input, Icon } from "../../shared/ui";
import { useLogin } from "./useLogin";

import "./../../styles/pages/forms-page.scss";

function Login() {
    const { register, handleSubmit, errors, onSubmit, loginError, isLoading } =
        useLogin();

    return (
        <Layout>
            <div className="formsPage">
                <div className="formsPage__icon formsPage__icon--login">
                    <Icon name="login-icon" />
                </div>
                <h2 className="formsPage__title">Авторизация</h2>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formsPage__form"
                >
                    <div className="form-row">
                        <Input
                            register={register}
                            name="login"
                            type="text"
                            placeholder="Ваш логин"
                            label="Логин:"
                            error={errors.login?.message}
                        />
                    </div>
                    <div className="form-row">
                        <Input
                            register={register}
                            name="password"
                            type="password"
                            placeholder="Ваш пароль"
                            label="Пароль:"
                            error={errors.password?.message}
                        />
                    </div>
                    <div className="form-row form-row--button">
                        <div className="form-element">
                            <Button
                                title={"Войти"}
                                buttonSize={3}
                                buttonStyle={2}
                                className={"button--centered"}
                                tag={"button"}
                                type={"submit"}
                                isWithLoader={isLoading}
                            />
                        </div>
                    </div>
                </form>
                {loginError && (
                    <p className="form-error form-error--global">
                        {loginError}
                    </p>
                )}
            </div>
        </Layout>
    );
}

export default Login;
