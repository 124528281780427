import React from "react";
import Layout from "../../components/Layout";
import { Button, Input, Icon } from "../../shared/ui";
import { useRegistration } from "./useRegistration";

import "./../../styles/components/main.scss";
import "./../../styles/components/forms/forms.scss";
import "./../../styles/components/inputs.scss";

function Registration() {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        registrationError,
        isLoading,
    } = useRegistration();

    return (
        <Layout>
            <div className="formsPage">
                <div className="formsPage__icon formsPage__icon--registration">
                    <Icon name="registration-icon" />
                </div>
                <h2 className="formsPage__title">Регистрация</h2>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formsPage__form"
                >
                    <div className="form-row">
                        <Input
                            register={register}
                            name="login"
                            type="text"
                            placeholder="Ваш логин"
                            label="Логин:"
                            error={errors.login?.message}
                        />
                    </div>
                    <div className="form-row">
                        <Input
                            register={register}
                            name="password"
                            type="password"
                            placeholder="Ваш пароль"
                            label="Пароль:"
                            error={errors.password?.message}
                        />
                    </div>
                    <div className="form-row">
                        <Input
                            register={register}
                            name="passwordRepeat"
                            type="password"
                            placeholder="Повторите пароль"
                            label="Повторите пароль:"
                            error={errors.passwordRepeat?.message}
                        />
                    </div>
                    <div className="form-row">
                        <Input
                            register={register}
                            name="email"
                            type="email"
                            placeholder="Ваш email"
                            label="Email:"
                            error={errors.email?.message}
                        />
                    </div>
                    <div className="form-row">
                        <Input
                            register={register}
                            name="phone"
                            type="text"
                            placeholder="Ваш телефон"
                            label="Телефон:"
                            error={errors.phone?.message}
                        />
                    </div>
                    <div className="form-row">
                        <Input
                            register={register}
                            name="firstName"
                            type="text"
                            placeholder="Ваше имя"
                            label="Имя:"
                            error={errors.firstName?.message}
                        />
                    </div>
                    <div className="form-row">
                        <Input
                            register={register}
                            name="lastName"
                            type="text"
                            placeholder="Ваша фамилия"
                            label="Фамилия:"
                            error={errors.lastName?.message}
                        />
                    </div>
                    <div className="form-row form-row--button">
                        <div className="form-element">
                            <Button
                                title={"Зарегистрироваться"}
                                className={"button--centered"}
                                tag={"button"}
                                type={"submit"}
                                isWithLoader={isLoading}
                            />
                        </div>
                    </div>
                </form>
                {registrationError && (
                    <p className="form-error form-error--global">
                        {registrationError}
                    </p>
                )}
            </div>
        </Layout>
    );
}

export default Registration;
