import React, { forwardRef } from "react";
import MapS from "react-map-gl";
import Loader from "../Loader";
import { MAPBOX_TOKEN } from "../../constants/variables";
import useMapComponent from "./useMapComponent";

const MapComponent = forwardRef((props, ref) => {
  const {
    viewState,
    isMapLoadedAndReady,
    isCanvasRendered,
    canvasRef,
    userLocation,
    userMarker,
    onMapMoveHandler,
    onMapMoveEndHandler,
    setIsMapLoaded,
    mapStyle,
    maxZoom,
    projection,
    preserveDrawingBuffer,
    scrollZoom,
    renderWorldCopies,
    className,
    isWithCanvas,
    onClick,
    onMouseMove,
    children,
  } = useMapComponent(props, ref);

  return (
    <>
      {isMapLoadedAndReady ? <Loader size={"big"} type={"content"} /> : null}
      <div className={`map__container ${className}`}>
        {viewState && (
          <MapS
            initialViewState={viewState}
            maxZoom={maxZoom}
            projection={projection}
            onLoad={() => setIsMapLoaded(true)}
            onMove={onMapMoveHandler}
            onMoveEnd={onMapMoveEndHandler}
            onClick={onClick}
            onMouseMove={onMouseMove}
            mapStyle={mapStyle}
            mapboxAccessToken={MAPBOX_TOKEN}
            preserveDrawingBuffer={preserveDrawingBuffer}
            scrollZoom={scrollZoom}
            renderWorldCopies={renderWorldCopies}
            ref={ref}
          >
            {userLocation && userMarker}
            {children}
          </MapS>
        )}
      </div>
      {isWithCanvas && (
        <canvas
          ref={canvasRef}
          className={`map__canvas ${
            isCanvasRendered ? "map__canvas--show" : ""
          }`}
        />
      )}
    </>
  );
});

export default MapComponent;
