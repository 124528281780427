import {
  FETCH_DRAW_RADII_START,
  FETCH_DRAW_RADII_SUCCESS,
  FETCH_DRAW_RADII_FAILURE,
} from "../actions/mapActions";

const initialState = {
  drawRadii: [],
  loading: false,
  error: null,
};

export default function mapReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DRAW_RADII_START:
      return { ...state, loading: true };
    case FETCH_DRAW_RADII_SUCCESS:
      return { ...state, drawRadii: action.payload };
    case FETCH_DRAW_RADII_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
