import { useCallback } from "react";

const useCanvasUpdater = (setIsCanvasRendered) => {
  const updateCanvas = useCallback(
    (mapRef, canvasRef, data, drawRadius, isShowFlights) => {
      if (!mapRef.current || !canvasRef.current || !data) return;

      const map = mapRef.current.getMap();
      const mask = canvasRef.current;
      const ctx = mask.getContext("2d");

      const showCanvas = () => {
        mask.classList.add("map__canvas--show");
      };

      showCanvas();

      const size = map.getCanvas().getBoundingClientRect();
      mask.width = size.width;
      mask.height = size.height;
      ctx.clearRect(0, 0, mask.width, mask.height);

      ctx.fillStyle = "rgba(0, 0, 0, 1)";
      ctx.fillRect(0, 0, mask.width, mask.height);

      ctx.globalCompositeOperation = "destination-out";
      data.forEach(([lon, lat, alt]) => {
        if (!isShowFlights && alt >= 1000) return;
        const p = map.project([lon, lat]);
        ctx.beginPath();
        ctx.arc(p.x, p.y, drawRadius, 0, 2 * Math.PI);
        ctx.fill();
      });

      ctx.globalCompositeOperation = "source-over";

      if (isShowFlights) {
        ctx.beginPath();
        data.forEach(([lon, lat, alt]) => {
          if (alt >= 1000) {
            const p = map.project([lon, lat]);
            ctx.moveTo(p.x + drawRadius, p.y);
            ctx.arc(p.x, p.y, drawRadius, 0, 2 * Math.PI);
          }
        });

        ctx.fillStyle = "rgba(255, 0, 0, 0.9)";
        ctx.fill();
      }

      setIsCanvasRendered(true);
    },
    [setIsCanvasRendered]
  );

  return updateCanvas;
};

export default useCanvasUpdater;
