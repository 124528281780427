import { useCallback } from "react";

const usePluralize = () => {
  const pluralize = useCallback((number, word) => {
    const cases = [2, 0, 1, 1, 1, 2];
    const titles = {
      континент: ["континент", "континента", "континентов"],
      страна: ["страна", "страны", "стран"],
      город: ["город", "города", "городов"],
      регион: ["регион", "региона", "регионов"],
      достопримечательность: [
        "достопримечательность",
        "достопримечательности",
        "достопримечательностей",
      ],
    };

    return titles[word][
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  }, []);

  return pluralize;
};

export default usePluralize;
