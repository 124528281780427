import React from "react";

import Layout from "../../components/Layout";

import "./../../styles/components/places-list.scss";

function DirectionsPage() {
    return (
        <>
            <Layout>Список всех стран с фильтрацией</Layout>
        </>
    );
}

export default DirectionsPage;
