export const WORLD_COORDS = {
  latitude: 0,
  longitude: 0,
  zoom: 0,
  projection: "mercator",
  drawRadius: 1,
};

export const EUROPE_COORDS = {
  latitude: 57.0,
  longitude: 10.0,
  zoom: 3.3,
  projection: "mercator",
  drawRadius: 4,
};

export const ASIA_COORDS = {
  latitude: 56.0,
  longitude: 120.0,
  zoom: 2.1,
  projection: "mercator",
  drawRadius: 3,
};

export const AFRICA_COORDS = {
  latitude: 1.0,
  longitude: 21.0,
  zoom: 3,
  projection: "mercator",
  drawRadius: 4,
};

export const NORTH_AMERICA_COORDS = {
  latitude: 64.0,
  longitude: -95.0,
  zoom: 2.1,
  projection: "mercator",
  drawRadius: 3,
};

export const SOUTH_AMERICA_COORDS = {
  latitude: -27.0,
  longitude: -60.0,
  zoom: 3,
  projection: "mercator",
  drawRadius: 4,
};

export const AUSTRALIA_COORDS = {
  latitude: -27.0,
  longitude: 134.0,
  zoom: 4,
  projection: "mercator",
  drawRadius: 5,
};

export const ANTARCTICA_COORDS = {
  latitude: -90.0,
  longitude: 0.0,
  zoom: 3.2,
  projection: "globe",
  drawRadius: 4,
};
